
import Vue, { PropType } from 'vue';
import { mapGetters, mapActions } from 'vuex';
import Property from '@/interfaces/property.interface';
import FavoriteProperty from '@/interfaces/favoriteProperty.interface';
import tracking from '@/functions/tracking';
import { EventBus } from '@/functions/eventBus';
import Api from '@/functions/api';
import FavoritePropertiesResponse from '@/interfaces/responses/favoritePropertiesResponse.interface';

interface IData {
    buttonText: string;
    isSaving: boolean;
    isDesktop: boolean;
    addAnimationIn: boolean;
    addAnimationOut: boolean;
}

interface ITexts {
    saveLabel: string;
    savedLabel: string;
    removeLabel: string;
    removedLabel: string;
    loadingLabel: string;
    altTextButton: string;
}

export default {
    data(): IData {
        return {
            buttonText: EventBus.isDesktop ? this.texts.loadingLabel : '',
            isSaving: false,
            isDesktop: EventBus.isDesktop,
            addAnimationIn: false,
            addAnimationOut: false,
        };
    },

    beforeMount(): void {
        EventBus.$on('app.resize', () => {
            this.isDesktop = EventBus.isDesktop;
        });
    },

    beforeUnmount(): void {
        EventBus.$off('app.resize');
    },

    props: {
        property: {
            type: Object as PropType<Property>,
            default: () => ({}),
        },
        texts: {
            type: Object as PropType<ITexts>,
            default: () => ({
                saveLabel: '',
                savedLabel: '',
                removeLabel: '',
                removedLabel: '',
            }),
        },
    },

    mounted() {
        Vue.nextTick(() => {
            if (this.isDesktop) {
                try {
                    Api.getFavoriteProperties()
                        .then((response: FavoritePropertiesResponse) => {
                            const propertyToFind = this.property as Property;

                            const isFound = response.properties.some(
                                (element: FavoriteProperty) => {
                                    if (
                                        element.propertyId ===
                                            propertyToFind.propertyId &&
                                        element.brokerId ===
                                            propertyToFind.brokerId
                                    ) {
                                        return true;
                                    }
                                    return false;
                                },
                            );

                            this.buttonText = isFound
                                ? this.texts.removeLabel
                                : this.texts.saveLabel;
                        })
                        .catch(() => {
                            this.buttonText = this.texts.saveLabel;
                        });
                } catch (e) {
                    this.buttonText = this.texts.saveLabel;
                }
            }
        });

        const buttonTextElement = this.$refs.buttonText as HTMLSpanElement;

        buttonTextElement?.addEventListener('animationend', (e) => {
            const animName = (e as AnimationEvent).animationName;

            if (animName === 'animateIn') {
                setTimeout(() => {
                    this.addAnimationOut = true;
                }, 3000);
            }

            if (animName === 'animateOut') {
                this.addAnimationOut = false;
                this.addAnimationIn = false;
            }
        });
    },

    watch: {
        propertyIsFavorite(newValue: boolean, oldValue: boolean): void {
            if (!this.isDesktop) {
                this.isSaving = true;
                this.addAnimationIn = true;
                if (newValue !== oldValue) {
                    setTimeout(() => {
                        this.isSaving = false;
                    }, 3000);
                }
            }
        },
    },

    computed: {
        ...mapGetters({
            isLoggedIn: 'user/isLoggedIn',
            favoriteProperties: 'user/favoriteProperties',
        }),

        propertyIsFavorite(): boolean {
            return this.favoriteProperties.some((element: FavoriteProperty) => {
                if (
                    element.propertyId === this.property.propertyId &&
                    element.brokerId === this.property.brokerId
                ) {
                    return true;
                }
                return false;
            });
        },
    },

    methods: {
        ...mapActions({
            setFavoriteProperty: 'user/setFavoriteProperty',
            setCurrentProperty: 'user/setCurrentProperty',
        }),

        changeText({
            newText,
            delay,
            callback,
        }: {
            newText: string;
            delay: number;
            callback?: () => void;
        }) {
            const buttonTextElement = this.$refs.buttonText as HTMLSpanElement;
            buttonTextElement.classList.add('hidden');
            setTimeout(() => {
                buttonTextElement.textContent = newText;
                buttonTextElement.classList.remove('hidden');
                if (callback) {
                    setTimeout(callback, delay);
                }
            }, 500); // Match this duration with the CSS transition duration
        },

        onLoggedInClick(e: MouseEvent | TouchEvent): void {
            e.stopPropagation();
            e.preventDefault();

            this.setFavoriteProperty({
                propertyId: this.property.propertyId,
                brokerId: this.property.brokerId,
            });

            Vue.nextTick(() => {
                const isFavorite = this.propertyIsFavorite;

                tracking.trackRawEvent({
                    event: 'trackCta',
                    eventData: {
                        category: 'Favoritter',
                        action: isFavorite
                            ? 'Fjern som favorit'
                            : 'Gem som favorit gennemfoert',
                        label: 'Boligpraesentation - Direkte',
                    },
                });
                const a = isFavorite
                    ? this.texts.removedLabel
                    : this.texts.savedLabel;

                if (this.isDesktop) {
                    const b = isFavorite
                        ? this.texts.saveLabel
                        : this.texts.removeLabel;

                    this.changeText({
                        newText: a,
                        delay: 3000,
                        callback: () => {
                            this.changeText({
                                newText: b,
                                delay: 3000,
                            });
                        },
                    });
                }

                if (!this.isDesktop) {
                    this.buttonText = a;
                }
            });
        },

        onNotLoggedInClick(): void {
            this.setCurrentProperty(this.property);
            this.$store.dispatch('modal/open', 'favoriteProperty');
            tracking.trackRawEvent({
                event: 'trackCta',
                eventData: {
                    category: 'Favoritter',
                    action: 'Gem som favorit initieret',
                    label: 'Boligpraesentation',
                },
            });
        },
    },
};
