import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "o-salesValuation__stepWrapper"
}
const _hoisted_2 = { class: "o-salesValuation__information" }
const _hoisted_3 = ["textContent"]
const _hoisted_4 = ["title"]
const _hoisted_5 = { class: "o-salesValuation__inputWrapper" }
const _hoisted_6 = {
  ref: "hiddenComment",
  hidden: "",
  class: "o-salesValuation__textarea",
  "data-comment": ""
}
const _hoisted_7 = { class: "o-salesValuation__navigation" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = ["textContent"]
const _hoisted_10 = {
  key: 1,
  class: "o-salesValuation__stepWrapper"
}
const _hoisted_11 = { class: "o-salesValuation__information" }
const _hoisted_12 = ["textContent"]
const _hoisted_13 = { class: "o-salesValuation__inputWrapper" }
const _hoisted_14 = { class: "o-salesValuation__navigation" }
const _hoisted_15 = { class: "o-salesValuation__disclaimer" }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = ["innerHTML"]
const _hoisted_19 = { class: "o-salesValuation__navigationButtons" }
const _hoisted_20 = ["textContent"]
const _hoisted_21 = ["disabled"]
const _hoisted_22 = ["textContent"]
const _hoisted_23 = ["textContent"]
const _hoisted_24 = {
  key: 2,
  class: "o-salesValuation__stepWrapper o-salesValuation__stepWrapper--confirmation"
}
const _hoisted_25 = { class: "u-row" }
const _hoisted_26 = {
  key: 0,
  class: "u-col-12 md:u-col-7 lg:u-col-5"
}
const _hoisted_27 = { class: "o-salesValuation__confirmation" }
const _hoisted_28 = ["textContent"]
const _hoisted_29 = { class: "o-salesValuation__confirmationNotice" }
const _hoisted_30 = { class: "o-salesValuation__confirmationCheckmark" }
const _hoisted_31 = ["textContent"]
const _hoisted_32 = { class: "o-panel o-salesValuation__overlay" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_icon = _resolveComponent("a-icon")!
  const _component_m_input = _resolveComponent("m-input")!
  const _component_m_textarea = _resolveComponent("m-textarea")!
  const _component_m_select = _resolveComponent("m-select")!
  const _component_m_checkbox = _resolveComponent("m-checkbox")!
  const _component_o_employee_card = _resolveComponent("o-employee-card")!
  const _component_o_accordion = _resolveComponent("o-accordion")!
  const _component_portal = _resolveComponent("portal")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "salesValuation",
    class: _normalizeClass(["o-salesValuation", `o-salesValuation--${$props.texts.backgroundColour}`])
  }, [
    ($data.step === 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "first"),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("p", {
              class: "a-paragraph",
              textContent: _toDisplayString($props.texts.stepOneHelpText)
            }, null, 8 /* PROPS */, _hoisted_3),
            ($options.showPopupSubjectsInfoIcon)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  type: "button",
                  class: "o-salesValuation__info",
                  title: $props.texts.ariaLabel,
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.openPopup && $options.openPopup(...args)))
                }, [
                  _createVNode(_component_a_icon, { name: "info" })
                ], 8 /* PROPS */, _hoisted_4))
              : _createCommentVNode("v-if", true)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_m_input, {
              value: $data.form.address,
              class: "m-input__field o-salesValuation__input o-salesValuation__input--dawa",
              name: "address‌_search",
              error: $data.errors.address,
              label: `${$props.formSettings.addressPlaceholder}*`,
              type: "dawa",
              onDawa: $options.enableSubmit,
              onDawaReset: $options.disableSubmit,
              onInput: $options.setAddressInfo
            }, null, 8 /* PROPS */, ["value", "error", "label", "onDawa", "onDawaReset", "onInput"]),
            _createVNode(_component_m_input, {
              modelValue: $data.form.name,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.form.name) = $event)),
              class: "m-input__field o-salesValuation__input",
              name: "name",
              error: $data.errors.name,
              label: `${$props.formSettings.namePlaceholder}*`
            }, null, 8 /* PROPS */, ["modelValue", "error", "label"]),
            _createVNode(_component_m_input, {
              modelValue: $data.form.mail,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($data.form.mail) = $event)),
              class: "m-input__field o-salesValuation__input",
              name: "mail",
              type: "email",
              error: $data.errors.mail,
              label: `${$props.formSettings.mailPlaceholder}*`
            }, null, 8 /* PROPS */, ["modelValue", "error", "label"]),
            _createVNode(_component_m_input, {
              modelValue: $data.form.phone,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($data.form.phone) = $event)),
              class: "m-input__field o-salesValuation__input",
              name: "name",
              type: "tel",
              error: $data.errors.phone,
              label: `${$props.formSettings.phonePlaceholder}*`
            }, null, 8 /* PROPS */, ["modelValue", "error", "label"]),
            _createElementVNode("div", _hoisted_6, [
              (!$options.showValuationWeekend)
                ? (_openBlock(), _createBlock(_component_m_textarea, {
                    key: 0,
                    modelValue: $data.form.comment,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($data.form.comment) = $event)),
                    class: "o-fields__field",
                    label: $props.formSettings.commentPlaceholder
                  }, null, 8 /* PROPS */, ["modelValue", "label"]))
                : _createCommentVNode("v-if", true)
            ], 512 /* NEED_PATCH */)
          ]),
          _createElementVNode("div", _hoisted_7, [
            ($props.consent && !$options.showValuationWeekend)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "a-small a-small--allowBreak o-salesValuation__disclaimer",
                  innerHTML: $props.consent.purposeText
                }, null, 8 /* PROPS */, _hoisted_8))
              : _createCommentVNode("v-if", true),
            _createElementVNode("button", {
              type: "button",
              class: _normalizeClass(["m-button o-salesValuation__inputButton", {
                        'm-button--outlined': $options.showValuationWeekend,
                        isLoading: $data.isLoading,
                    }]),
              onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => ($options.onClick && $options.onClick(...args)))
            }, [
              _createElementVNode("span", {
                class: "m-button__text",
                textContent: _toDisplayString(
                            $options.showValuationWeekend
                                ? $props.texts.nextButtonText
                                : $props.texts.buttonText
                        )
              }, null, 8 /* PROPS */, _hoisted_9),
              ($options.showValuationWeekend)
                ? (_openBlock(), _createBlock(_component_a_icon, {
                    key: 0,
                    class: "m-button__icon",
                    name: "arrow_forward"
                  }))
                : _createCommentVNode("v-if", true)
            ], 2 /* CLASS */)
          ])
        ]))
      : _createCommentVNode("v-if", true),
    ($data.step === 2)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _renderSlot(_ctx.$slots, "second"),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("p", {
              class: "a-paragraph",
              textContent: _toDisplayString($props.texts.stepTwoHelpText)
            }, null, 8 /* PROPS */, _hoisted_12)
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_m_select, {
              modelValue: $data.form.date,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($data.form.date) = $event)),
              class: "o-salesValuation__input o-salesValuation__input--select",
              error: $data.errors.date,
              options: $options.dateOptions
            }, null, 8 /* PROPS */, ["modelValue", "error", "options"]),
            ($data.isDesktop)
              ? (_openBlock(), _createBlock(_component_m_input, {
                  key: 0,
                  modelValue: $data.form.comment,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($data.form.comment) = $event)),
                  class: "m-input__field o-salesValuation__input",
                  name: "name",
                  label: $props.formSettings.commentPlaceholder
                }, null, 8 /* PROPS */, ["modelValue", "label"]))
              : _createCommentVNode("v-if", true),
            (!$data.isDesktop)
              ? (_openBlock(), _createBlock(_component_m_textarea, {
                  key: 1,
                  modelValue: $data.form.comment,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($data.form.comment) = $event)),
                  class: "o-fields__field",
                  label: $props.formSettings.commentPlaceholder
                }, null, 8 /* PROPS */, ["modelValue", "label"]))
              : _createCommentVNode("v-if", true)
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              ($props.texts.checkboxLabel && $options.showValuationWeekend)
                ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                    _createVNode(_component_m_checkbox, {
                      modelValue: $data.form.checkboxConsent,
                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (($data.form.checkboxConsent) = $event)),
                      class: "m-input__field",
                      error: $data.errors.checkboxConsent,
                      label: $props.texts.checkboxLabel
                    }, null, 8 /* PROPS */, ["modelValue", "error", "label"])
                  ]))
                : _createCommentVNode("v-if", true),
              ($props.texts.mandatoryText && $options.showValuationWeekend)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: "a-small a-small--content a-small--allowBreak has-anchor",
                    innerHTML: $props.texts.mandatoryText
                  }, null, 8 /* PROPS */, _hoisted_17))
                : _createCommentVNode("v-if", true),
              ($props.consent)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 2,
                    class: "a-small a-small--allowBreak",
                    innerHTML: $props.consent.purposeText
                  }, null, 8 /* PROPS */, _hoisted_18))
                : _createCommentVNode("v-if", true)
            ]),
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("button", {
                type: "button",
                class: "m-button m-button--transparent",
                onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => ($options.decrementStep && $options.decrementStep(...args)))
              }, [
                _createVNode(_component_a_icon, {
                  class: "m-button__icon",
                  name: "arrow_back"
                }),
                _createElementVNode("span", {
                  class: "m-button__text",
                  textContent: _toDisplayString($props.texts.backButtonText)
                }, null, 8 /* PROPS */, _hoisted_20)
              ]),
              ($options.showValuationWeekend)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    type: "button",
                    disabled: 
                            !$data.form.checkboxConsent &&
                            $props.texts.checkboxLabel.length > 0
                        ,
                    class: _normalizeClass(["m-button", { isLoading: $data.isLoading }]),
                    onClick: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => ($options.onClick && $options.onClick(...args)))
                  }, [
                    _createElementVNode("span", {
                      class: "m-button__text",
                      textContent: _toDisplayString($props.texts.buttonText)
                    }, null, 8 /* PROPS */, _hoisted_22)
                  ], 10 /* CLASS, PROPS */, _hoisted_21))
                : _createCommentVNode("v-if", true),
              (!$options.showValuationWeekend)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 1,
                    type: "button",
                    class: _normalizeClass(["m-button", { isLoading: $data.isLoading }]),
                    onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => ($options.onClick && $options.onClick(...args)))
                  }, [
                    _createElementVNode("span", {
                      class: "m-button__text",
                      textContent: _toDisplayString($props.texts.buttonText)
                    }, null, 8 /* PROPS */, _hoisted_23)
                  ], 2 /* CLASS */))
                : _createCommentVNode("v-if", true)
            ])
          ])
        ]))
      : _createCommentVNode("v-if", true),
    ($data.step === 3)
      ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
          _createElementVNode("div", _hoisted_25, [
            ($data.assignedEmployee)
              ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                  _createVNode(_component_o_employee_card, {
                    panel: true,
                    employee: $data.assignedEmployee,
                    class: "o-panel__brokerCard o-salesValuation__broker"
                  }, null, 8 /* PROPS */, ["employee"])
                ]))
              : _createCommentVNode("v-if", true),
            _createElementVNode("div", {
              class: _normalizeClass(["u-col-12", $data.assignedEmployee ? ' md:u-col-5 lg:u-col-7' : ''])
            }, [
              _createElementVNode("div", _hoisted_27, [
                _createElementVNode("h2", {
                  class: "a-heading2",
                  textContent: _toDisplayString($options.formattedConfirmation)
                }, null, 8 /* PROPS */, _hoisted_28),
                _createElementVNode("div", _hoisted_29, [
                  _createElementVNode("div", _hoisted_30, [
                    _createVNode(_component_a_icon, { name: "check" })
                  ]),
                  _createElementVNode("span", {
                    class: "a-label",
                    textContent: _toDisplayString($props.texts.confirmationText)
                  }, null, 8 /* PROPS */, _hoisted_31)
                ])
              ])
            ], 2 /* CLASS */)
          ])
        ]))
      : _createCommentVNode("v-if", true),
    ($options.showSalesValuationInfo)
      ? (_openBlock(), _createBlock(_component_portal, {
          key: 3,
          to: "modal"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_32, [
              _createVNode(_component_o_accordion, { items: $props.popupSubjects }, null, 8 /* PROPS */, ["items"])
            ])
          ], undefined, true),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}