
import Vue, {
    PropType,
    h,
    ref,
    createApp,
    App as Application,
    resolveComponent,
} from 'vue';
import type { Ref, Component } from 'vue';
import aIcon from '@/components/atoms/Icon.vue';
import aEnergyLabel from '@/components/atoms/EnergyLabel.vue';
import PoiCategories from '@/constants/poiCategories.const';
import PoiCategory from '@/interfaces/POICategory.interface';
import Vue3TouchEvents from 'vue3-touch-events';
import store from '@/store';

import Maplibre, { Map, Marker, LngLatBounds, Popup } from 'maplibre-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { mapGetters, mapActions } from 'vuex';
import tracking from '@/functions/tracking';
import { EventBus } from '@/functions/eventBus';
import { drawStyles, mapLayers } from '@/functions/map';
import FreehandMode from '@/vendor/mapbox-gl-draw-freehand-mode';
import { FullRoute } from '@/store/pointsOfInterest/interface';
import {
    BOUNDS,
    LATITUDE,
    LONGITUDE,
    MAX_BOUNDS,
    ZOOM,
} from '@/constants/search.const';
import MapAddress from '@/interfaces/mapAddress.interface';
import MapBroker from '@/interfaces/mapBroker.interface';
import MapCluster from '@/interfaces/mapCluster.interface';
import MapDot from '@/interfaces/mapDot.interface';
import MapFavorite from '@/interfaces/mapFavorite.interface';
import MapNeighborhood from '@/interfaces/mapNeighborhood.interface';
import MapPolygon from '@/interfaces/mapPolygon.interface';
import MapProperty from '@/interfaces/mapProperty.interface';
import mLoader from './Loader.vue';
import oPropertyPreview from '@/components/organisms/property/PropertyPreview.vue';
import oBrokerPreview from '@/components/organisms/broker/BrokerPreview.vue';
import POI from '@/interfaces/POI.interface';
import oBrokerMapCard from '@/components/organisms/broker/BrokerMapCard.vue';
import oPropertyCard from '@/components/organisms/property/PropertyCard.vue';
import FavoriteProperty from '@/interfaces/favoriteProperty.interface';

let map: any | null = null;
let draw: any | null = null;

interface MapSource {
    data: {
        features: (
            | MapCluster
            | MapDot
            | MapNeighborhood
            | MapPolygon
            | MapProperty
        )[];
        type: string;
        geometry?: {};
    };
    type: string;
    cluster?: boolean;
    clusterMaxZoom?: number;
    clusterRadius?: number;
    clusterProperties?: object;
}

export interface Texts {
    close: string;
    drawArea: string;
    helpTextDesktop: string;
    helpTextMobile: string;
    removeArea: string;
    openHouseTeaser: string;
    openHouseTeaserSignup: string;
}

interface Offset {
    bottom: number[];
    center: number[];
    'bottom-left': number[];
    'bottom-right': number[];
    left: number[];
    right: number[];
    top: number[];
    'top-left': number[];
    'top-right': number[];
}

interface Data {
    adressMarker: Marker;
    brokerMarkers: Marker[];
    brokerPreviewApp: Application;
    currentZoom: number;
    drawnArea: number[][];
    drawState: string;
    favoriteMarkers: Marker[];
    fitted: boolean;
    fitToNeighborhoods: boolean;
    fitToProperties: boolean;
    fitToBrokers: boolean;
    fitToFavorites: boolean;
    fitToRelatedBrokers: boolean;
    hasTouch: boolean;
    initialCenter: number[];
    initialLoad: boolean;
    initialZoom: number;
    isDesktop: boolean;
    layers: any;
    mapObject: Map;
    neighborhoodMarkers: Marker[];
    opened: string;
    openPopupObject: Popup;
    poiMarkers: Marker[];
    poiTypes: PoiCategory[];
    previewIndex: number;
    propertyMarkers: Marker[];
    propertyPreviewApp: Application;
    style: any;
    currentFavorite: MapFavorite | null;
    terrainType: string;
    filteredFavorites: MapFavorite[];
    offset: Offset;
}

export default {
    name: 'm-map',
    components: {
        mLoader,
        oPropertyCard,
        oPropertyPreview,
        oBrokerPreview,
        oBrokerMapCard,
    },

    props: {
        addZoom: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
        address: {
            type: Object as PropType<MapAddress | null>,
            default: () => null,
        },
        brokers: {
            type: Array as PropType<MapBroker[]>,
            default: () => [],
        },
        type: {
            type: String as PropType<string>,
            default: () => '',
        },
        center: {
            type: Array as PropType<number[]>,
            default: () => [LONGITUDE, LATITUDE],
        },
        favorites: {
            type: Array as PropType<MapFavorite[]>,
            default: () => [],
        },
        fitNeighborhoods: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
        fitProperties: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
        fitBrokers: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
        fitFavorites: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
        hovered: {
            type: String as PropType<string | null>,
            default: () => null,
        },
        isLoading: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
        interactive: {
            type: Boolean as PropType<boolean>,
            default: () => true,
        },
        lockToFit: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
        maxBounds: {
            type: Array as PropType<{ lat: number; lng: number }[]>,
            default: () => MAX_BOUNDS,
        },
        message: {
            type: String as PropType<string | null>,
            default: () => null,
        },
        municipalityPolygon: {
            type: Array as PropType<number[][] | null>,
            default: () => [],
        },
        neighborhoods: {
            type: Array as PropType<(MapCluster | MapNeighborhood)[]>,
            default: () => [],
        },
        pois: {
            type: Array as PropType<POI[] | []>,
            default: () => [],
        },
        polygon: {
            type: Array as PropType<number[][] | null>,
            default: () => BOUNDS,
        },
        properties: {
            type: Array as PropType<(MapCluster | MapDot | MapProperty)[]>,
            default: () => [],
        },
        scrollZoom: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
        showDraw: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
        showPolygon: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
        texts: {
            type: Object as PropType<Texts>,
            default: () => ({
                close: '',
                drawArea: '',
                helpTextDesktop: '',
                helpTextMobile: '',
                removeArea: '',
                openHouseTeaser: '',
                openHouseTeaserSignup: '',
            }),
        },
        zoom: {
            type: Number as PropType<number>,
            default: () => ZOOM,
        },
        mapType: {
            type: String as PropType<string | null>,
            default: () => null,
        },
        doFavoriteClusters: {
            type: Boolean as PropType<boolean>,
            default: () => false,
            required: false,
        },
        favoriteClustersMaxZoom: {
            type: Number as PropType<number>,
            default: () => 15,
        },
        showFavorites: {
            type: Boolean as PropType<boolean>,
            default: () => true,
            required: false,
        },
        isComingSoon: {
            type: Boolean as PropType<boolean>,
            default: false,
        },
    },

    data(): Data {
        return {
            adressMarker: null,
            brokerMarkers: [],
            brokerPreviewApp: null,
            currentZoom: this.zoom,
            drawnArea: [],
            drawState: '',
            favoriteMarkers: [],
            fitted: !!this.address,
            fitToBrokers: this.fitBrokers,
            fitToFavorites: this.fitFavorites,
            fitToNeighborhoods: this.fitNeighborhoods,
            fitToProperties: this.fitProperties,
            fitToRelatedBrokers: false,
            hasTouch: EventBus.hasTouch,
            initialCenter: this.center,
            initialLoad: true,
            initialZoom: this.zoom,
            isDesktop: EventBus.isDesktop,
            layers: mapLayers,
            mapObject: null,
            neighborhoodMarkers: [],
            opened: '',
            openPopupObject: null,
            poiMarkers: [],
            poiTypes: PoiCategories,
            previewIndex: 0,
            propertyMarkers: [],
            propertyPreviewApp: null,
            style: 'https://danbolig2022.tiles.viamap.net/v1/style.json?token=eyJkcGZ4IjogImRhbmJvbGlnMjAyMiIsICJyZWYiOiAiMiIsICJwYXIiOiAiIiwgInByaXZzIjogInIxWjByMEYwazZCdFdxUWNPVXlrQi95NlNVcEp2MlFiZ3lYZXRxNEhZNFhPLzNZclcwK0s5dz09In0.HzVWSsJd/xm1oAcjoDSfEyIoJwuM4gCQYqr+qEoP79uDUWQ2hPXtBEVTlphUYukXqqwU/eoHqiL23LHREEbp9w',
            currentFavorite: null,
            terrainType: 'terrain',
            filteredFavorites: [],
            offset: {
                top: [0, 0],
                'top-left': [16, 16],
                'top-right': [-16, 16],
                bottom: [0, -64],
                'bottom-left': [16, -48],
                'bottom-right': [-16, -48],
                left: [48, 0],
                right: [-48, 0],
                center: [0, 0],
            },
        };
    },

    computed: {
        ...mapGetters({
            currentPoi: 'pointsOfInterest/currentPoi',
            currentRoutes: 'pointsOfInterest/currentRoutes',
            currentRoute: 'pointsOfInterest/currentRoute',
            selectedTransportationMethod:
                'pointsOfInterest/selectedTransportationMethod',
            favoriteProperties: 'user/favoriteProperties',
            isDrawingArea: 'search/isDrawingArea',
        }),

        /**
         * Source for clusters layer.
         *
         * @return {MapSource}
         */
        clustersSource(): MapSource {
            return {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: this.neighborhoods.length
                        ? this.neighborhoods
                        : this.properties,
                },
            };
        },

        /**
        /**
         * Return the favorites to render on page by current zoom
         *
         * @return {MapFavorite[]}
         */
        favoritesToRender(): MapFavorite[] | boolean {
            if (!this.doFavoriteClusters) {
                return this.favorites;
            }
            return this.filteredFavorites;
        },

        /**
         * Source for dots layer.
         *
         * @return {MapSource}
         */
        dotsSource(): MapSource {
            return {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: this.properties,
                },
            };
        },

        /**
         * Get current level of neighborhood clusters/pins.
         *
         * @return {number}
         */
        neighborhoodsLevel(): number {
            let hasCount =
                (this.neighborhoods as any[])[0]?.properties?.count !==
                undefined;
            let totalCount = this.neighborhoods.length;
            if (!hasCount && totalCount <= 10) {
                return 3;
            }

            if (!hasCount && totalCount <= 100) {
                return 2;
            }

            return 1;
        },

        /**
         * Get current level of properties clusters/pins.
         *
         * @return {number}
         */
        propertiesLevel(): number {
            let hasCount =
                (this.properties as any[])[0]?.properties?.count !== undefined;
            let totalCount = this.properties.length;

            if (!hasCount && totalCount <= 50) {
                return 4;
            }

            if (!hasCount && totalCount <= 100) {
                return 3;
            }

            if (!hasCount && this.currentZoom >= 8) {
                return 2;
            }

            return 1;
        },
    },

    watch: {
        favoriteProperties() {
            if (this.propertiesLevel >= 3) {
                this.updatePropertyMarkers();
            }
        },

        currentFavorite(newVal) {
            if (newVal) {
                this.favoriteMarkers.forEach((marker) => {
                    if (
                        marker?.getElement().dataset.id ===
                        newVal.properties.data.id
                    ) {
                        marker.getElement().classList.add('isOpen');
                        marker
                            .getElement()
                            .querySelector('.m-map__marker')
                            ?.classList.add('isOpen');
                    } else if (
                        marker?.getElement().classList.contains('isOpen')
                    ) {
                        marker.getElement().classList.remove('isOpen');
                        marker
                            .getElement()
                            .querySelector('.m-map__marker')
                            ?.classList.remove('isOpen');
                    }
                });
            } else {
                document
                    .querySelectorAll(
                        '.isOpen.maplibregl-marker[data-type="favorite"]',
                    )
                    .forEach((el) => {
                        el.classList.remove('isOpen');
                        el
                            .querySelector('.m-map__marker')
                            ?.classList.remove('isOpen');
                    });
            }
        },

        currentPoi(newVal) {
            if (newVal) {
                this.poiMarkers.forEach((marker) => {
                    if (
                        marker?.getElement().dataset.id === newVal.geometry[1]
                    ) {
                        marker.getElement().classList.add('isOpen');
                        marker
                            .getElement()
                            .querySelector('.m-map__marker')
                            ?.classList.add('isOpen');
                    } else if (
                        marker?.getElement().classList.contains('isOpen')
                    ) {
                        marker.getElement().classList.remove('isOpen');
                        marker
                            .getElement()
                            .querySelector('.m-map__marker')
                            ?.classList.remove('isOpen');
                    }
                });
            } else {
                document
                    .querySelectorAll(
                        '.isOpen.maplibregl-marker[data-type="poi"]',
                    )
                    .forEach((el) => {
                        el.classList.remove('isOpen');
                        el
                            .querySelector('.m-map__marker')
                            ?.classList.remove('isOpen');
                    });
            }
        },

        /**
         * Watch the chosenRoute to handle map when new routes are chosen
         * E.g. to extend map bounds if route is outside current bounds
         *
         * @param {boolean} newValue
         * @return {void}
         */
        currentRoute(newValue: FullRoute) {
            if (map.getSource('route')) {
                map.getSource('route').setData({
                    type: 'Feature',
                    geometry: {
                        type: 'LineString',
                        coordinates: this.currentRoute
                            ? [...this.currentRoute.routePolyline]
                            : [],
                    },
                });

                map.getSource('routeStart').setData({
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: this.currentRoute
                            ? this.currentRoute.routePolyline[0]
                            : [],
                    },
                });
            } else if (newValue?.routePolyline.length) {
                this.addCurrentRouteGeoLayer();
            }

            const marker = this.adressMarker.getElement();
            const markerElement = marker.querySelector('.maplibregl-marker');
            if (newValue?.routePolyline.length) {
                const routeBounds = this.checkBounds(newValue.routePolyline);
                const mapBounds = map.getBounds();
                if (
                    !this.checkIfBoundsAreWithinBounds(routeBounds, mapBounds)
                ) {
                    const newBounds = mapBounds.extend(routeBounds);
                    map.fitBounds(
                        newBounds,
                        {
                            padding: 50,
                            maxZoom: 16,
                        },
                        {
                            isProgrammatic: true,
                        },
                    );
                }
                markerElement.classList.remove('m-map__marker--address');
                markerElement.classList.add('m-map__poiMarker');
                markerElement.innerText = '';
            } else {
                markerElement.classList.add('m-map__marker--address');
                markerElement.classList.remove('m-map__poiMarker');
                markerElement.innerText = this.address.properties.label;
            }
        },

        /**
         * Watch the addZoom prop to zoom in on map
         *
         * @param {boolean} newValue
         * @return {void}
         */
        addZoom: {
            handler(newValue: boolean): void {
                if (newValue) this.easeZoomIn();
            },
        },

        /**
         * Watch the current zoom prop to zoom in on map
         *
         * @param {boolean} newValue
         * @return {void}
         */
        currentZoom(newVal) {
            if (newVal) {
                this.updateBrokerMarkers();
            }
        },

        /**
         * Watch the drawState value.
         *
         * @param {string} newValue
         * @return {void}
         */
        drawState(newValue: string): void {
            if (!map) {
                return;
            }
            if (map.getSource('clusters')) {
                map.setLayoutProperty(
                    'clustersCircle',
                    'visibility',
                    `${this.propertiesLevel >= 3 || newValue ? 'none' : 'visible'}`,
                );
                map.setLayoutProperty(
                    'clustersCount',
                    'visibility',
                    `${this.propertiesLevel >= 3 || newValue ? 'none' : 'visible'}`,
                );
            }
            if (map.getSource('dots')) {
                map.setLayoutProperty(
                    'dots',
                    'visibility',
                    `${this.propertiesLevel >= 3 || newValue ? 'none' : 'visible'}`,
                );
            }
            if (map.getSource('favorites')) {
                map.setLayoutProperty(
                    'clustersCircleFavorites',
                    'visibility',
                    `${newValue ? 'none' : 'visible'}`,
                );
                map.setLayoutProperty(
                    'clustersCountFavorites',
                    'visibility',
                    `${newValue ? 'none' : 'visible'}`,
                );
            }

            [...this.brokerMarkers, ...this.propertyMarkers].forEach(
                (marker) => {
                    if (newValue) {
                        marker?.getElement().classList.add('hidden');
                    } else {
                        marker?.getElement().classList.remove('hidden');
                    }
                },
            );
        },

        /**
         * Watch the brokers value.
         *
         * @return {void}
         */
        brokers: {
            handler(): void {
                if (
                    (this.fitToBrokers || this.fitToRelatedBrokers) &&
                    this.brokers.length
                ) {
                    this.fitBounds('brokers');
                    this.fitToBrokers = false;
                }
                if (this.brokers.length) {
                    this.updateBrokerMarkers();
                }
            },
            deep: true,
        },

        pois: {
            handler(newVal) {
                this.updatePoiMarkers();
            },
            deep: true,
        },

        /**
         * Watch the favorites value passed to the map and do fitting if chosen.
         *
         * @return {void}
         */
        favorites(val): void {
            if (this.fitToFavorites && this.favorites.length) {
                this.fitBounds('favorites');
                this.fitToFavorites = false;
            }
            if (this.favorites.length && this.doFavoriteClusters) {
                this.updateFavoritesGeoLayer();
            }
        },

        favoritesToRender(val) {
            if (this.showFavorites) this.updateFavoritesMarkers(val);
        },

        /**
         * Watch the opened value
         *
         * @return {void}
         */
        opened(newVal) {
            [
                ...this.brokerMarkers,
                ...this.neighborhoodMarkers,
                ...this.propertyMarkers,
                ...this.favoriteMarkers,
            ].forEach((marker) => {
                if (marker?.getElement().dataset.id === newVal) {
                    marker.getElement().classList.add('isOpen');
                    marker
                        .getElement()
                        .querySelector('.m-map__marker')
                        ?.classList.add('isOpen');
                    if (marker.getElement().dataset.type === 'broker') {
                        const image = marker.getElement().querySelector('IMG');
                        image.src = image.dataset.logoWhite;
                    }
                } else if (marker?.getElement().classList.contains('isOpen')) {
                    marker.getElement().classList.remove('isOpen');
                    marker
                        .getElement()
                        .querySelector('.m-map__marker')
                        ?.classList.remove('isOpen');
                    if (marker.getElement().dataset.type === 'broker') {
                        const image = marker.getElement().querySelector('IMG');
                        image.src = image.dataset.logo;
                    }
                }
            });
        },

        hovered(newVal) {
            [...this.brokerMarkers, ...this.propertyMarkers].forEach(
                (marker) => {
                    if (marker?.getElement().dataset.id === newVal) {
                        marker.getElement().classList.add('isHovered');
                        marker
                            .getElement()
                            .querySelector('.m-map__marker')
                            ?.classList.add('isHovered');
                        if (marker.getElement().dataset.type === 'broker') {
                            const image = marker
                                .getElement()
                                .querySelector('IMG');
                            image.src = image.dataset.logoWhite;
                        }
                    } else if (
                        marker?.getElement().classList.contains('isHovered')
                    ) {
                        marker.getElement().classList.remove('isHovered');
                        marker
                            .getElement()
                            .querySelector('.m-map__marker')
                            ?.classList.remove('isHovered');
                        if (
                            marker.getElement().dataset.type === 'broker' &&
                            !marker?.getElement().classList.contains('isOpen')
                        ) {
                            const image = marker
                                .getElement()
                                .querySelector('IMG');
                            image.src = image.dataset.logo;
                        }
                    }
                },
            );
        },

        /**
         * Watch the properties value.
         *
         * @return {void}
         */
        properties(newVal): void {
            if (
                this.fitToProperties &&
                !this.initialLoad &&
                this.properties.length
            ) {
                this.fitBounds('properties');
                this.fitToProperties = false;
            }
            if (this.propertiesLevel >= 3) {
                this.updatePropertyMarkers();
            } else {
                this.removePropertyMarkers();
                this.updatePropertyGeoLayers();
            }
            if (map.getSource('clusters')) {
                map.setLayoutProperty(
                    'clustersCircle',
                    'visibility',
                    `${this.propertiesLevel >= 3 || this.drawState !== '' ? 'none' : 'visible'}`,
                );
                map.setLayoutProperty(
                    'clustersCount',
                    'visibility',
                    `${this.propertiesLevel >= 3 || this.drawState !== '' ? 'none' : 'visible'}`,
                );
            }
            if (map.getSource('dots')) {
                map.setLayoutProperty(
                    'dots',
                    'visibility',
                    `${this.propertiesLevel >= 3 || this.drawState !== '' ? 'none' : 'visible'}`,
                );
            }

            if (this.mapType === 'neighborhoodmap') {
                this.addNeighborhoodMarkers();
            }
        },

        /**
         * Watch the neighborhoods value.
         *
         * @return {void}
         */
        neighborhoods(): void {
            if (
                this.fitToNeighborhoods &&
                !this.initialLoad &&
                this.neighborhoods.length
            ) {
                this.fitToNeighborhoods = false;
                this.fitBounds('neighborhoods');
            }
            if (map.getSource('clusters')) {
                map.getSource('clusters').setData({
                    type: 'FeatureCollection',
                    features: this.neighborhoods,
                });
            }
            if (
                this.drawState === '' &&
                this.neighborhoodsLevel >= 2 &&
                this.neighborhoods.length >= 1
            ) {
                this.addNeighborhoodMarkers();
            } else {
                this.removeNeighborhoodMarkers();
            }
            const polygonsSource = this.constructPolygonsSource(
                this.neighborhoods,
            );
            if (
                polygonsSource &&
                polygonsSource.data.features.length &&
                !map.getSource('polygons')
            ) {
                this.addPolygonsGeoLayer(polygonsSource);
            } else if (polygonsSource && map.getSource('polygons')) {
                map.getSource('polygons').setData({ ...polygonsSource.data });
            }
        },

        /**
         * Watch the properties value.
         *
         * @return {void}
         */
        neighborhoodsLevel(newVal: number): void {
            if (newVal === 1 && !map.getSource('clusters')) {
                this.addNeighborhoodsGeoLayer();
            }
        },

        /**
         * Watch the favorites value.
         *
         * @param {boolean} doShow
         * @return {void}
         */
        showFavorites(doShow: boolean): void {
            if (map.getSource('favorites')) {
                map.setLayoutProperty(
                    'clustersCircleFavorites',
                    'visibility',
                    `${doShow ? 'visible' : 'none'}`,
                );
                map.setLayoutProperty(
                    'clustersCountFavorites',
                    'visibility',
                    `${doShow ? 'visible' : 'none'}`,
                );
            }
            if (doShow) {
                this.updateFavoritesMarkers();
            } else {
                this.removeFavoritesMarkers(this.favoriteMarkers);
            }
        },

        /**
         * Watch the showPolygon value.
         *
         * @param {boolean} newValue
         * @return {void}
         */
        showPolygon(newValue: boolean): void {
            if (map.getSource('polygon')) {
                map.getSource('polygon').setData({
                    features: [
                        <MapPolygon>{
                            geometry: {
                                coordinates: [this.polygon ?? []],
                                type: 'Polygon',
                            },
                            type: 'Feature',
                        },
                    ],
                    type: 'FeatureCollection',
                });
                map.setLayoutProperty(
                    'polygonFill',
                    'visibility',
                    `${newValue ? 'visible' : 'none'}`,
                );
                map.setLayoutProperty(
                    'polygonLine',
                    'visibility',
                    `${newValue ? 'visible' : 'none'}`,
                );
            } else {
                this.addPolygonGeoLayer();
            }
        },
    },

    mounted() {
        map = new Map({
            attributionControl: false,
            center: this.initialCenter, // starting position [lng, lat]
            container: 'map', // container id
            crossSourceCollisions: false,
            dragRotate: false,
            interactive: this.interactive,
            maxBounds: this.maxBounds,
            maxZoom: 20,
            minZoom: 4,
            style: this.style, // style URL
            zoom: this.initialZoom, // starting zoom
            scrollZoom: this.scrollZoom,
        });
        map.on('load', async (event) => {
            this.onMapLoaded(event.target);
        });
        map.on('zoom', () => {
            this.onZoomUpdate();
        });
        map.on('move', () => {
            this.onMapMove();
        });
        map.on('moveend', (mapObject) => {
            const { isProgrammatic } = mapObject;
            this.onMapMoveEnd(mapObject, isProgrammatic);
        });
    },

    beforeMount(): void {
        EventBus.$on('app.resize', () => {
            this.hasTouch = EventBus.hasTouch;
            this.isDesktop = EventBus.isDesktop;
        });

        EventBus.$on('app.click', (event: any) => {
            if (event.target.closest('.o-propertyPreview') !== null) {
                return;
            }

            if (
                !event?.target?.parentElement?.classList?.contains(
                    'm-map__marker',
                ) &&
                !event?.target?.classList?.contains('m-map__marker')
            ) {
                this.opened = '';
            }

            if (!event?.target?.closest('.m-map__marker--favorite')) {
                this.currentFavorite = null;
            }
        });

        EventBus.$on('search.reset', this.onReset);

        EventBus.$on('search.query', () => {
            this.fitToProperties = true;
        });

        EventBus.$on('brokerSearch.reset', () => {
            map.flyTo({
                center: [LONGITUDE, LATITUDE],
                zoom: ZOOM,
            });
        });

        EventBus.$on('brokerSearch.query', () => {
            this.fitToBrokers = true;
        });

        EventBus.$on('brokerSearch.relatedQuery', () => {
            this.fitToRelatedBrokers = true;
        });
    },

    beforeUnmount(): void {
        if (map && map.getLayer('polygon')) map.removeLayer('polygon');
        if (map && map.getLayer('polygonLine')) map.removeLayer('polygonLine');
        EventBus.$off('brokerSearch.query');
        EventBus.$off('brokerSearch.relatedQuery');
        EventBus.$off('search.query');
        EventBus.$off('search.reset');
        EventBus.$off('app.resize');
        if (map) map.off('click', 'clustersCircle');
    },

    methods: {
        ...mapActions({
            setSelectedTransportationMethod:
                'pointsOfInterest/setSelectedTransportationMethod',
            setCurrentPoi: 'pointsOfInterest/setCurrentPoi',
            setIsDrawingArea: 'search/setIsDrawingArea',
        }),
        /**
         * Method for adding geo json layer to the map
         */
        addCurrentRouteGeoLayer(): void {
            map.addSource('route', {
                type: 'geojson',
                data: {
                    type: 'Feature',
                    geometry: {
                        type: 'LineString',
                        coordinates: this.currentRoute
                            ? this.currentRoute.routePolyline
                            : [],
                    },
                    features: [],
                },
            });
            map.addLayer(this.layers.route);

            map.addSource('routeStart', {
                type: 'geojson',
                data: {
                    geometry: {
                        coordinates: this.currentRoute
                            ? this.currentRoute.routePolyline[0]
                            : [],
                        type: 'Point',
                    },
                    type: 'Feature',
                },
            });
            map.addLayer(this.layers.routeStart);
            map.addLayer(this.layers.routeStartInner);
            map.addLayer(this.layers.routeStartDot);
        },

        /**
         * Method for adding geo json layer to the map for drawn polygon
         */
        addPolygonGeoLayer(): void {
            if (map.getSource('polygon')) return;
            map.addSource('polygon', {
                type: 'geojson',
                data: {
                    features: [
                        <MapPolygon>{
                            geometry: {
                                coordinates: [this.polygon ?? []],
                                type: 'Polygon',
                            },
                            type: 'Feature',
                        },
                    ],
                    type: 'FeatureCollection',
                },
            });
            map.addLayer(this.layers.polygonFill);
            map.addLayer(this.layers.polygonLine);
            map.setLayoutProperty('polygonFill', 'visibility', 'visible');
            map.setLayoutProperty('polygonLine', 'visibility', 'visible');

            // move the layers below
            // DOCS: https://maplibre.org/maplibre-gl-js/docs/API/classes/Style/#movelayer
            map.moveLayer('polygonFill', 'place_label_city_large');
            map.moveLayer('polygonLine', 'place_label_city_large');
        },

        /**
         * Method for adding geo json layer to the map for multiple polygons e.g. neighborhoods
         */
        addPolygonsGeoLayer(polygonsSource): void {
            map.addSource('polygons', {
                type: 'geojson',
                ...polygonsSource,
            });
            map.addLayer(this.layers.polygons);

            if (this.isComingSoon) {
                // map.addLayer(this.layers.comingSoon);
                map.addLayer(this.layers.comingSoonFill);
            }
        },

        /**
         * Method for adding geo json layer for neighborhoods or properties to the map
         */
        addNeighborhoodsGeoLayer(): void {
            map.addSource('clusters', {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: this.neighborhoods,
                },
                cluster: false,
            });
            map.addLayer(this.layers.clustersCircle);
            map.addLayer(this.layers.clustersCount);
            this.addClickEventsForClusterLayer();
        },

        addClickEventsForClusterLayer() {
            map.on('click', 'clustersCircle', (e: any) => {
                const features = map.queryRenderedFeatures(e.point, {
                    layers: ['clustersCircle'],
                });
                map.easeTo({
                    center: features[0].geometry.coordinates,
                    zoom: Math.round(this.currentZoom + 1),
                });
            });
            map.on('mouseenter', 'clustersCircle', (e) => {
                map.getCanvas().style.cursor = 'pointer';
            });
            map.on('mouseleave', 'clustersCircle', () => {
                map.getCanvas().style.cursor = 'grab';
            });
        },

        /**
         * Source for polygons layer.
         *
         * @return {MapSource}
         */
        constructPolygonsSource(neighborhoods): MapSource | boolean {
            let features: MapPolygon[] = [];
            if (!neighborhoods.length) return false;
            neighborhoods.forEach((neighborhood: any): any => {
                if (neighborhood?.properties?.data?.multiPolygon) {
                    neighborhood?.properties?.data?.multiPolygon.forEach(
                        (polygon: number[][]) => {
                            features.push({
                                geometry: {
                                    coordinates: [polygon],
                                    type: 'Polygon',
                                },
                                type: 'Feature',
                            });
                        },
                    );
                }
            });
            return {
                data: {
                    features,
                    type: 'FeatureCollection',
                },
                type: 'geojson',
            };
        },

        removeFavoritesMarkers(favoritesToRemove) {
            favoritesToRemove.forEach((markerToRemove) => {
                const indexToDelete = this.favoriteMarkers.findIndex(
                    (marker) =>
                        markerToRemove?.getElement().dataset.id ===
                        marker?.getElement().dataset.id,
                );
                delete this.favoriteMarkers[indexToDelete];
                markerToRemove.remove();
            });
        },

        removeBrokerMarkers() {
            this.brokerMarkers.forEach((marker) => {
                marker?.addClassName('hidden');
            });
        },

        removeNeighborhoodMarkers() {
            this.neighborhoodMarkers.forEach((markerToRemove) => {
                const indexToDelete = this.neighborhoodMarkers.findIndex(
                    (marker) =>
                        markerToRemove?.getElement().dataset.id ===
                        marker?.getElement().dataset.id,
                );
                delete this.neighborhoodMarkers[indexToDelete];
                markerToRemove.remove();
            });
        },

        removePropertyMarkers() {
            this.propertyMarkers.forEach((markerToRemove) => {
                const indexToDelete = this.propertyMarkers.findIndex(
                    (marker) =>
                        markerToRemove?.getElement().dataset.id ===
                        marker?.getElement().dataset.id,
                );
                delete this.propertyMarkers[indexToDelete];
                markerToRemove.remove();
            });

            const maplibreglPopupHTML = document.querySelector(
                '.maplibregl-popup',
            ) as HTMLDivElement;
            maplibreglPopupHTML?.remove();
        },

        removePoiMarkers(POIsToRemove): void {
            POIsToRemove.forEach((markerToRemove) => {
                markerToRemove.remove();
            });
        },

        updateBrokerMarkers() {
            if (
                (this.currentZoom >= 11 || this.type === 'broker') &&
                this.drawState === ''
            ) {
                this.addBrokerMarkers();
            } else {
                this.removeBrokerMarkers();
            }
        },

        addAddressMarker() {
            const container = document.createElement('div');
            const inner = document.createElement('div');

            container.setAttribute('data-type', 'address');
            inner.classList.add(
                'm-map__marker',
                'm-map__marker--address',
                'maplibregl-marker',
            );
            inner.innerHTML = this.address.properties.label;

            container.appendChild(inner);

            const adressMarker = new Marker({
                anchor: 'bottom',
                className: 'm-marker',
                element: container,
            })
                .setLngLat(this.address.geometry.coordinates)
                .addTo(map);

            this.adressMarker = adressMarker;
        },

        updateFavoritesMarkers() {
            const favoritesToRemove = this.favoriteMarkers.filter((marker) => {
                return !this.favoritesToRender.some(
                    (favoriteToRender) =>
                        favoriteToRender.properties.data.id ===
                        marker?.getElement().dataset.id,
                );
            });
            this.removeFavoritesMarkers(favoritesToRemove);
            this.favoriteMarkers = this.favoritesToRender.map((favorite) => {
                const existingMarker = this.favoriteMarkers.find(
                    (favoriteMarker) =>
                        favoriteMarker?.getElement().dataset.id ===
                        favorite.properties.data.id,
                );
                if (existingMarker) {
                    return existingMarker;
                }
                const container = document.createElement('div');
                const inner = document.createElement('div');
                const textSpan = document.createElement('span');
                const iconSpan = document.createElement('span');
                const favoriteObj = favorite;

                container.addEventListener('click', (e) => {
                    this.onFavoriteClick(e, favoriteObj);
                });
                container.setAttribute(
                    'data-id',
                    `${favorite.properties.data.id}`,
                );
                container.setAttribute('data-type', 'favorite');

                inner.classList.add(
                    'm-map__marker',
                    'm-map__marker--favorite',
                    'maplibregl-marker',
                );

                textSpan.innerText = favorite.properties.label;

                iconSpan.classList.add('m-map__markerIcon');
                iconSpan.innerHTML = this.$refs.favoriteIcon.$el.outerHTML;
                inner.appendChild(textSpan);
                inner.appendChild(iconSpan);
                container.appendChild(inner);

                let marker = new Marker({
                    anchor: 'bottom',
                    className: 'm-marker',
                    element: container,
                })
                    .setLngLat(favorite.geometry.coordinates)
                    .addTo(map);

                if (this.isDesktop) {
                    let popup = new Popup({
                        closeButton: false,
                        closeOnMove: true,
                        offset: this.offset,
                    })
                        .setMaxWidth('400px')
                        .setHTML(
                            `
                                <div class="m-map__preview o-favoritePreview popup-contentWrapper" data-id="${favorite.properties.data.id}">
                                    <span class="o-favoritePreview__icon">
                                        ${this.$refs.favoriteIcon.$el.outerHTML}
                                    </span>
                                    <p class="a-lead o-favoritePreview__title">${favorite.properties.data.title}</p>
                                    <p class="a-paragraph o-favoritePreview__description">${favorite.properties.data.description}</p>
                                    <label class="a-label o-favoritePreview__author">
                                        - ${favorite.properties.data.author}
                                    </label>
                                </div>
                            `,
                        )
                        .on('close', (e) => {
                            this.onPopupClose(e);
                        })
                        .on('open', (e) => {
                            this.onPopupOpen(e);
                        });
                    marker.setPopup(popup); // sets a popup on this marker
                }
                return marker;
            });
        },

        updateFavoritesGeoLayer() {
            if (map.getSource('favorites')) {
                map.getSource('favorites').setData({
                    type: 'FeatureCollection',
                    features: this.favorites,
                });
                if (map.getSource('polygons')) {
                    map.moveLayer('polygons', 'clustersCircleFavorites');
                }
                return;
            }
        },

        addBrokerMarkers() {
            if (this.brokerMarkers.length) {
                this.brokerMarkers.forEach((marker) => {
                    marker?.removeClassName('hidden');
                });
                return; // broker markers are either all rendered or not. No need to render new array
            }
            this.brokerMarkers = this.brokers.map((broker) => {
                if (Math.abs(broker.geometry.coordinates[0]) > 90) return;
                const container = document.createElement('div');
                container.classList.add('maplibregl-marker');
                container.setAttribute(
                    'data-id',
                    broker.properties.data.brokerId,
                );
                container.setAttribute('data-type', 'broker');
                const inner = document.createElement('div');
                inner.classList.add('m-map__marker', 'm-map__marker--broker');
                const image = document.createElement('img');
                image.classList.add('m-map__markerImage');
                image.alt = 'logo';
                image.width = 152;
                image.height = 35;
                image.src = '/assets/images/logo.svg';
                image.setAttribute(
                    'data-logo-white',
                    '/assets/images/logo_white.svg',
                );
                image.setAttribute('data-logo', '/assets/images/logo.svg');

                inner.appendChild(image);
                container.appendChild(inner);

                let marker = new Marker({
                    anchor: 'bottom',
                    className: 'm-marker',
                    element: container,
                })
                    .setLngLat(broker.geometry.coordinates)
                    .addTo(map);

                container.addEventListener('click', (e) => {
                    brokerObject.value = broker;
                    this.$nextTick(() => {
                        if (this.isDesktop) {
                            e.stopPropagation();
                            if (this.openPopupObject) {
                                this.openPopupObject.target.remove();
                            }
                            new Popup({
                                maxWidth: '400px',
                                closeButton: false,
                                closeOnMove: true,
                                offset: this.offset,
                            })
                                .setLngLat(broker.geometry.coordinates)
                                .setDOMContent(
                                    this.createBrokerPopupComponent(broker),
                                )
                                .on('close', (e) => {
                                    this.onPopupClose(e);
                                })
                                .on('open', (e) => {
                                    this.onPopupOpen(e);
                                })
                                .addTo(map);
                        } else {
                            this.onMarkerClick(e);
                        }
                    });
                });
                container.addEventListener('mouseover', () => {
                    this.setHovered(broker.properties.data.brokerId);
                });
                container.addEventListener('mouseout', () => {
                    this.setHovered(null);
                });

                return marker;
            });
        },

        addNeighborhoodMarkers() {
            this.removeNeighborhoodMarkers();
            this.neighborhoodMarkers = this.neighborhoods.map(
                (neighborhood) => {
                    const container = document.createElement('div');
                    container.classList.add('maplibregl-marker');
                    container.setAttribute(
                        'data-id',
                        neighborhood.properties.data.id,
                    );
                    container.setAttribute('data-type', 'neighborhood');

                    const hidePin =
                        this.properties.length > 0 || this.isComingSoon
                            ? true
                            : false;
                    container.setAttribute(
                        'data-hasproperties',
                        `${hidePin ? 'true' : 'false'}`,
                    );

                    const inner = document.createElement('div');
                    inner.classList.add(
                        'm-map__marker',
                        'm-map__marker--neighborhood',
                    );
                    inner.innerText = neighborhood.properties.label;

                    container.appendChild(inner);
                    let marker = new Marker({
                        anchor: 'bottom',
                        className: 'm-marker',
                        element: container,
                    })
                        .setLngLat(neighborhood.geometry.coordinates)
                        .addTo(map);

                    container.addEventListener('click', (e) => {
                        this.setPreviewIndex(0);
                        this.$nextTick(() => {
                            e.stopPropagation();
                            if (this.openPopupObject) {
                                this.openPopupObject.target.remove();
                            }
                            new Popup({
                                maxWidth: '400px',
                                closeButton: false,
                                closeOnMove: true,
                                offset: this.offset,
                            })
                                .setLngLat(neighborhood.geometry.coordinates)
                                .setDOMContent(
                                    this.createNeighborhoodPopupComponent(
                                        neighborhood,
                                    ),
                                )
                                .on('close', (e) => {
                                    this.onPopupClose(e);
                                })
                                .on('open', (e) => {
                                    this.onPopupOpen(e);
                                })
                                .addTo(map);
                            this.onMarkerClick(e);
                        });
                    });
                    container.addEventListener('mouseover', () => {
                        this.setHovered(neighborhood.properties.data.id);
                    });
                    container.addEventListener('mouseout', () => {
                        this.setHovered(null);
                    });

                    return marker;
                },
            );
        },

        updatePropertyMarkers() {
            this.removePropertyMarkers();

            this.propertyMarkers = this.properties.map((property) => {
                const container = document.createElement('div');
                container.classList.add('maplibregl-marker');
                container.setAttribute(
                    'data-id',
                    property.properties.data[0].propertyId,
                );
                container.setAttribute('data-type', 'property');

                const inner = document.createElement('div');
                inner.classList.add('m-map__marker', 'm-map__marker--property');

                if (this.properties.length <= 50) {
                    inner.classList.add('hasLongLabel');
                }
                if (property.properties.data.length > 1) {
                    inner.classList.add('isStack');
                }
                if (property.properties.data[0].isSold) {
                    inner.classList.add('isSold');
                }

                const labelSpan = document.createElement('span');
                if (
                    property.properties.data.length > 1 &&
                    property.properties.data.length ===
                        property.properties.data.filter((x) => x.isSold).length
                ) {
                    labelSpan.innerText = `${property.properties.data.length} Solgte`;
                } else if (property.properties.data[0].isSold) {
                    labelSpan.innerText = `Solgt`;
                } else {
                    labelSpan.innerHTML = property.properties.label;
                }
                inner.appendChild(labelSpan);

                if (
                    property.properties.data.filter((item) => item.openHouse)
                        .length
                ) {
                    const openHouseItems = property.properties.data.filter(
                        (item) => item.openHouse,
                    );
                    const openHouseLabel = document.createElement('div');
                    openHouseLabel.classList.add('m-map__marker--openHouse');
                    openHouseLabel.innerText = openHouseItems[0].openHouseShort;

                    inner.appendChild(openHouseLabel);
                    inner.classList.add('hasOpenHouse');
                }

                const filteredProperties = property.properties.data.filter(
                    (property) =>
                        this.favoriteProperties.some(
                            (fav) =>
                                fav.propertyId === property.propertyId &&
                                fav.brokerId === property.brokerId,
                        ),
                );

                if (filteredProperties.length) {
                    const mySpan = document.createElement('span');
                    mySpan.classList.add('m-map__marker__favorite');

                    mySpan.innerHTML = `<svg viewBox="0 0 24 24" class="a-icon">
                                        <path d="M11.1928 1.10402C11.5923 0.557659 12.4077 0.557658 12.8072 1.10401L16.1833 5.72133C16.3067 5.89013 16.48 6.01599 16.6787 6.08121L22.1132 7.86523C22.7563 8.07633 23.0083 8.85191 22.6121 9.40068L19.2641 14.0384C19.1417 14.2079 19.0755 14.4116 19.0749 14.6207L19.0576 20.3406C19.0555 21.0174 18.3958 21.4967 17.7514 21.2895L12.3061 19.5384C12.1071 19.4744 11.8929 19.4744 11.6939 19.5384L6.24857 21.2895C5.60424 21.4967 4.94449 21.0174 4.94244 20.3406L4.92511 14.6207C4.92448 14.4116 4.85831 14.2079 4.73591 14.0384L1.38785 9.40068C0.991686 8.85191 1.24369 8.07633 1.88675 7.86523L7.32134 6.08121C7.52002 6.01599 7.69326 5.89013 7.81668 5.72133L11.1928 1.10402Z" />
                                        </svg>`;
                    inner.prepend(mySpan);
                }

                container.appendChild(inner);
                let marker = new Marker({
                    anchor: 'bottom',
                    className: 'm-marker',
                    element: container,
                })
                    .setLngLat(property.geometry.coordinates)
                    .addTo(map);

                container.addEventListener('click', (e) => {
                    propertyObject.value = property;
                    this.setPreviewIndex(0);
                    this.$nextTick(() => {
                        if (this.isDesktop) {
                            e.stopPropagation();
                            if (this.openPopupObject) {
                                this.openPopupObject.target.remove();
                            }
                            new Popup({
                                maxWidth: '400px',
                                closeButton: false,
                                closeOnMove: true,
                                offset: this.offset,
                            })
                                .setLngLat(property.geometry.coordinates)
                                .setDOMContent(
                                    this.createPropertyPopupComponent(property),
                                )
                                .on('close', (e) => {
                                    this.onPopupClose(e);
                                })
                                .on('open', (e) => {
                                    this.onPopupOpen(e);
                                })
                                .addTo(map);
                        } else {
                            this.onMarkerClick(e);
                        }
                    });
                });
                container.addEventListener('mouseover', () => {
                    this.setHovered(property.properties.data[0].propertyId);
                });
                container.addEventListener('mouseout', () => {
                    this.setHovered(null);
                });

                return marker;
            });
        },

        createBrokerPopupComponent(broker: MapBroker): HTMLElement {
            if (this.brokerPreviewApp) {
                return this.brokerPreviewApp.$el;
            }
            const brokerPreviewApp = createApp({
                render() {
                    return h(
                        'div',
                        {
                            'data-id':
                                brokerObject.value.properties.data.brokerId,
                            class: 'popup-contentWrapper',
                        },
                        h(oBrokerPreview as unknown as Component, {
                            ...brokerPreviewProps, // TODO under ts fejl
                        }),
                    );
                },
            });
            const el = document.createElement('div');
            brokerPreviewApp.use(store);
            const mountedApp = brokerPreviewApp.mount(el);
            this.brokerPreviewApp = mountedApp;
            return mountedApp.$el;
        },

        createNeighborhoodPopupComponent(
            neighborhood: MapNeighborhood,
        ): HTMLElement {
            const neighborhoodPreview = document.createElement('div');
            neighborhoodPreview.classList.add(
                'o-neighborhoodMapPreview',
                'm-map__preview',
                'popup-contentWrapper',
            );
            neighborhoodPreview.setAttribute(
                'data-id',
                neighborhood.properties.data.id,
            );

            const neighborhoodPreviewName = document.createElement('div');
            neighborhoodPreviewName.classList.add(
                'o-neighborhoodMapPreview__displayName',
            );
            neighborhoodPreviewName.innerText =
                neighborhood.properties.data.name;

            const neighborhoodPreviewForSale = document.createElement('div');
            neighborhoodPreviewForSale.classList.add(
                'o-neighborhoodMapPreview__propertiesForSale',
            );
            neighborhoodPreviewForSale.innerText =
                this.formattedNeighborhood(neighborhood);

            const neighborhoodPreviewAmenetiesContainer =
                document.createElement('div');
            neighborhoodPreviewAmenetiesContainer.classList.add(
                'o-neighborhoodMapPreview__ameneties',
            );

            neighborhood.properties.data.facts.forEach((fact, index) => {
                const factSpan = document.createElement('span');
                factSpan.innerText = `${fact}${index < neighborhood.properties.data.facts.length - 1 ? ', ' : ''}`;
                neighborhoodPreviewAmenetiesContainer.appendChild(factSpan);
            });

            const neighborhoodPreviewActions = document.createElement('div');
            neighborhoodPreviewActions.classList.add(
                'o-neighborhoodMapPreview__actions',
            );

            const neighborhoodPreviewCTA = document.createElement('a');
            neighborhoodPreviewCTA.href = neighborhood.properties.data.url;
            neighborhoodPreviewCTA.classList.add('m-button');
            neighborhoodPreviewCTA.innerText = 'Udforsk nabolag';
            neighborhoodPreviewActions.append(neighborhoodPreviewCTA);

            neighborhoodPreview.appendChild(neighborhoodPreviewName);
            neighborhoodPreview.appendChild(neighborhoodPreviewForSale);
            neighborhoodPreview.appendChild(
                neighborhoodPreviewAmenetiesContainer,
            );
            neighborhoodPreview.appendChild(neighborhoodPreviewActions);
            return neighborhoodPreview;
        },

        createPropertyPopupComponent(property: MapProperty): HTMLElement {
            if (this.propertyPreviewApp) {
                return this.propertyPreviewApp.$el;
            }
            const propertyPreviewApp = createApp(
                {
                    props: ['passedFunction', 'passedOpenHouseTexts'],
                    render() {
                        const passedFuntion = this.passedFunction;
                        return h(
                            'div',
                            {
                                'data-id':
                                    propertyObject.value.properties.data[0]
                                        .propertyId,
                                class: 'popup-contentWrapper',
                            },
                            h(oPropertyPreview as unknown as Component, {
                                ...propertyPreviewProps,
                                openHouseTexts: this.passedOpenHouseTexts,
                                onNext(payload) {
                                    passedFuntion(payload);
                                },
                                onPrev(payload) {
                                    passedFuntion(payload);
                                },
                            }),
                        );
                    },
                },
                {
                    passedFunction: this.setPreviewIndex,
                    passedOpenHouseTexts: {
                        teaser: this.texts.openHouseTeaser,
                        teaserSignup: this.texts.openHouseTeaserSignup,
                    },
                },
            );
            const el = document.createElement('div');
            propertyPreviewApp.component('a-icon', aIcon as any);
            propertyPreviewApp.component('a-energy-label', aEnergyLabel as any);
            propertyPreviewApp.use(Vue3TouchEvents);
            propertyPreviewApp.use(store);
            const mountedApp = propertyPreviewApp.mount(el);
            this.propertyPreviewApp = mountedApp;
            return mountedApp.$el;
        },

        updatePropertyGeoLayers() {
            if (map.getSource('clusters')) {
                map.getSource('clusters').setData({
                    type: 'FeatureCollection',
                    features: this.properties,
                });
            } else {
                map.addSource('clusters', {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: this.properties,
                    },
                });
                map.addLayer(this.layers.clustersCircle);
                map.addLayer(this.layers.clustersCount);
                this.addClickEventsForClusterLayer();
            }
            if (map.getSource('dots')) {
                map.getSource('dots').setData({
                    type: 'FeatureCollection',
                    features: this.properties,
                });
            } else {
                map.addSource('dots', {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: this.properties,
                    },
                });
                map.addLayer(this.layers.dots);
            }
        },

        updatePoiMarkers(): void {
            this.removePoiMarkers(this.poiMarkers);
            this.poiMarkers = this.pois.map((poi) => {
                const container = document.createElement('div');
                const inner = document.createElement('div');
                container.setAttribute('data-type', 'poi');
                container.setAttribute('data-id', `${poi.geometry[1]}`);

                container.addEventListener('click', (e) => {
                    this.onMarkerClick(e, poi);
                    this.onPoiClick(poi);
                });
                container.classList.add('mapboxgl-marker', 'maplibregl-marker');

                inner.classList.add('m-map__marker', 'm-map__marker--poi');
                inner.addEventListener('click', (e) => {
                    this.onPoiClick(poi);
                });
                inner.innerHTML =
                    this.$refs[`poi_type_${poi.typeKey}`][0].$el.outerHTML;

                container.appendChild(inner);

                let marker = new Marker({
                    anchor: 'bottom',
                    className: 'm-marker',
                    element: container,
                })
                    .setLngLat([poi.poilatlng[1], poi.poilatlng[0]])
                    .addTo(map);

                return marker;
            });
        },

        formattedNeighborhood(neighborhood: MapNeighborhood): string {
            const noProperties =
                neighborhood.properties.data.propertiesForSale.startsWith(
                    '0',
                ) || neighborhood.properties.data.propertiesForSale === '';
            const noFavourites =
                neighborhood.properties.data.favourites.startsWith('0') ||
                neighborhood.properties.data.favourites === '';

            if (noProperties && noFavourites) {
                return '';
            }

            if (noProperties) {
                return neighborhood.properties.data.favourites;
            }

            if (noFavourites) {
                return neighborhood.properties.data.propertiesForSale;
            }

            return `${neighborhood.properties.data.propertiesForSale}, ${neighborhood.properties.data.favourites}`;
        },

        /**
         * Cluster features are formatted differently when returned by mapbox queryRenderedFeatures
         * Use this function to return expected format
         *
         * @param {object} feature
         * @return MapProperty
         */
        formatFavoriteFromClusterFeature(feature: any): MapProperty {
            return {
                ...feature,
                geometry: feature.geometry,
                properties: {
                    ...feature.properties,
                    data: JSON.parse(feature.properties.data),
                },
            };
        },

        /**
         * Change the index of the preview popup.
         *
         * @param {number} index
         * @return {void}
         */
        changePreviewIndex(index: number): void {
            this.previewIndex = index;
        },

        getPadding(): number {
            let mapWidth = window.screen.width;
            let mapWrapper = document.querySelector('.mgl-map-wrapper');

            if (mapWrapper) {
                mapWidth = mapWrapper.getBoundingClientRect().width;
                let returnValue = Math.round(mapWidth / 5);

                if (returnValue > 240) {
                    returnValue = 240;
                }
                return returnValue;
            }

            return 96;
        },

        /**
         * Fit bounds to properties.
         *
         * @return {void}
         */
        fitBounds(type: string): void {
            let bounds: [number, number][] = [];

            if (type === 'brokers') {
                if (this.fitToRelatedBrokers) {
                    bounds = this.brokers.map(
                        (b: MapBroker) => b.geometry.coordinates,
                    );
                    this.fitToRelatedBrokers = false;
                } else {
                    bounds = this.brokers
                        .slice(0, 2)
                        .map((b: MapBroker) => b.geometry.coordinates);
                }
            } else if (type === 'neighborhoods') {
                let polygon: number[][] | undefined = [];

                if (
                    this.municipalityPolygon &&
                    this.municipalityPolygon.length
                ) {
                    polygon = this.municipalityPolygon;
                } else if (this.neighborhoods.length) {
                    polygon = ([] as number[][]).concat(
                        ...(this.neighborhoods[0] as MapNeighborhood).properties
                            .data.multiPolygon,
                    );
                }

                if (polygon) {
                    bounds = polygon.map((p: number[]) => [p[0], p[1]]);
                }
            } else if (type === 'properties' && this.properties.length) {
                bounds = this.properties.map(
                    (p: MapCluster | MapDot | MapProperty) =>
                        p.geometry.coordinates,
                );
            } else if (type === 'favorites' && this.favorites.length) {
                bounds = this.favorites.map(
                    (p: MapCluster | MapDot | MapProperty) =>
                        p.geometry.coordinates,
                );
            }

            if (bounds && bounds.length > 0) {
                const fit: LngLatBounds =
                    type === 'favorites'
                        ? this.boundsWithFixedCenter(
                              [this.center[0], this.center[1]],
                              this.checkBounds(bounds),
                          )
                        : this.checkBounds(bounds);

                map.fitBounds(
                    fit,
                    {
                        padding:
                            type === 'favorites'
                                ? {
                                      top: 70,
                                      bottom: 10,
                                      left: 50,
                                      right: 50,
                                  }
                                : this.getPadding(),
                        maxZoom: type === 'brokers' ? 12 : 16,
                    },
                    {
                        isProgrammatic: true,
                    },
                );

                if (type === 'neighborhoods') {
                    this.fitted = true;
                }
            }
        },

        /**
         * Check bounds of property/neighborhood against current bounds.
         *
         * @return {void}
         */
        checkBounds(geo: [number, number][]): LngLatBounds {
            let bounds = new LngLatBounds();

            geo.forEach((g: [number, number]) => {
                if (
                    g[0] < this.maxBounds[0].lng ||
                    g[1] < this.maxBounds[0].lat ||
                    g[0] > this.maxBounds[1].lng ||
                    g[1] > this.maxBounds[1].lat
                ) {
                    return;
                }

                bounds.extend(g);
            });

            return bounds;
        },

        /**
         * Check if one set of LngLatBounds are contained within another set of LngLatBounds
         * Eg. are the bounds of a route containes with the bounds of the map
         *
         * @return {boolean}
         */
        checkIfBoundsAreWithinBounds(
            boundsToCheck: Maplibre.LngLatBounds,
            referenceBounds: Maplibre.LngLatBounds,
        ): boolean {
            if (boundsToCheck.getNorth() > referenceBounds.getNorth()) {
                return false;
            }
            if (boundsToCheck.getSouth() < referenceBounds.getSouth()) {
                return false;
            }
            if (boundsToCheck.getWest() < referenceBounds.getWest()) {
                return false;
            }
            if (boundsToCheck.getEast() > referenceBounds.getEast()) {
                return false;
            }
            return true;
        },

        /**
         * Check bounds of property/neighborhood against current bounds.
         *
         * @return {void}
         */
        boundsWithFixedCenter(
            fixedCenter: [number, number],
            fitWithoutCenter: Maplibre.LngLatBounds,
        ): Maplibre.LngLatBounds {
            const getNewExtreme = (
                toBeCenter: number,
                currentExtreme: [number, number],
            ): { high: number; low: number } => {
                const largestDifference = Math.max(
                    ...currentExtreme.map((extreme) =>
                        Math.abs(toBeCenter - extreme),
                    ),
                );
                return {
                    high: toBeCenter + largestDifference,
                    low: toBeCenter - largestDifference,
                };
            };
            let { high: newLatMax, low: newLatMin } = getNewExtreme(
                fixedCenter[1],
                [fitWithoutCenter.getNorth(), fitWithoutCenter.getSouth()],
            );
            let { high: newLngMax, low: newLngMin } = getNewExtreme(
                fixedCenter[0],
                [fitWithoutCenter.getWest(), fitWithoutCenter.getEast()],
            );
            return this.checkBounds([
                [newLngMax, newLatMax],
                [newLngMin, newLatMin],
            ]);
        },

        /**
         * Function for updating visibily of layers on map
         *
         * @return {void}
         */
        changeMapLayoutVisibility(
            layerName: string,
            makeVisible: boolean,
        ): void {
            map.setLayoutProperty(
                layerName,
                'visibility',
                `${makeVisible ? 'visible' : 'none'}`,
            );
        },

        /**
         * Handle "click" event on close area button.
         *
         * @return {void}
         */
        onClickCloseArea(): void {
            draw.deleteAll();
            draw.trash();
            this.drawnArea = [];
            this.drawState = '';
            map.getCanvas().style.cursor = 'grab';

            map.off('draw.create');
            map.off('draw.update');
            map.removeControl(draw);
            if (map.getSource('favorites')) {
                this.changeMapLayoutVisibility('clustersCircleFavorites', true);
                this.changeMapLayoutVisibility('clustersCountFavorites', true);
            }
            this.setIsDrawingArea(false);
        },

        /**
         * Handle "click" event on confirm area button.
         *
         * @return {void}
         */
        onClickConfirmArea(): void {
            draw.deleteAll();
            draw.trash();
            this.$emit('draw', this.drawnArea);
            this.drawState = '';
            map.getCanvas().style.cursor = 'grab';

            map.off('draw.create');
            map.off('draw.update');
            map.removeControl(draw);
            tracking.track(
                'trackBoligsoegning',
                'Boligsoegning',
                'Tegn omraade gennemfoert',
                'Tegn omraade',
            );
            this.setIsDrawingArea(false);
        },

        /**
         * Handle "click" event on delete area button.
         *
         * @return {void}
         */
        onClickDeleteArea(): void {
            draw.deleteAll();
            draw.trash();
            draw.changeMode('draw_polygon');
            map.getCanvas().style.cursor = 'crosshair';
            this.drawnArea = [];
            this.drawState = 'drawing';
            this.setIsDrawingArea(true);
        },

        /**
         * Handle "click" event on draw button.
         *
         * @return {void}
         */
        onClickDrawArea(): void {
            map.addControl(draw);
            map.on('draw.create', this.onDrawCreate);
            map.on('draw.update', this.onDrawUpdate);

            this.setIsDrawingArea(true);

            draw.changeMode('draw_polygon');
            map.getCanvas().style.cursor = 'crosshair';
            this.drawState = 'drawing';
            if (map.getSource('favorites')) {
                this.changeMapLayoutVisibility(
                    'clustersCircleFavorites',
                    false,
                );
                this.changeMapLayoutVisibility('clustersCountFavorites', false);
            }
            tracking.track(
                'trackBoligsoegning',
                'Boligsoegning',
                'Tegn omraade initieret',
                'Tegn omraade',
            );
        },

        /**
         * Handle "click" event on remove area button.
         *
         * @return {void}
         */
        onClickRemoveArea(): void {
            this.$emit('draw', null);
        },

        /**
         * Handle "draw.create" event.
         *
         * @param {any} event
         * @return {void}
         */
        onDrawCreate(event: any): void {
            if (event?.features[0]?.geometry?.coordinates[0]) {
                this.drawnArea = event.features[0].geometry.coordinates[0];
            } else {
                this.drawnArea =
                    draw.getAll().features[0].geometry.coordinates[0];
            }
            this.drawState = 'drawn';
            map.getCanvas().style.cursor = 'grab';
        },

        /**
         * Handle "draw.update" event.
         *
         * @param {any} event
         * @return {void}
         */
        onDrawUpdate(event: any): void {
            if (event?.features[0]?.geometry?.coordinates[0]) {
                this.drawnArea = event.features[0].geometry.coordinates[0];
            } else {
                this.drawnArea =
                    draw.getAll().features[0].geometry.coordinates[0];
            }
            this.drawState = 'drawn';
            map.getCanvas().style.cursor = 'grab';
        },

        onChangeMapLayout(): void {
            if (map) {
                switch (this.terrainType) {
                    case 'terrain':
                        map.setLayoutProperty(
                            'orthophoto',
                            'visibility',
                            'visible',
                        );
                        this.terrainType = 'sattelite';
                        break;
                    default:
                        map.setLayoutProperty(
                            'orthophoto',
                            'visibility',
                            'none',
                        );
                        this.terrainType = 'terrain';
                }
            }
        },

        /**
         * Handle "loaded" event for Mapbox map.
         *
         * @param {any} event
         * @return {void}
         */
        onMapLoaded(mapObject: any): void {
            map = mapObject;
            if (this.addZoom) {
                this.easeZoomIn();
            }
            this.$emit('load', {
                bounds: map.getBounds(),
            });

            this.onMapMoveEnd(mapObject, true);

            setTimeout(() => {
                if (this.showPolygon) {
                    this.addPolygonGeoLayer();
                    if (map.getLayer('polygon')) {
                        map.setLayoutProperty(
                            'polygon',
                            'visibility',
                            'visible',
                        );
                    }

                    if (map.getLayer('polygonLine')) {
                        map.setLayoutProperty(
                            'polygonLine',
                            'visibility',
                            'visible',
                        );
                    }
                }
            }, 1000);

            if (this.address) {
                this.addAddressMarker();
            }

            if (this.showDraw) {
                draw = new MapboxDraw({
                    displayControlsDefault: false,
                    keybindings: false,
                    userProperties: true,
                    modes: Object.assign(
                        MapboxDraw.modes,
                        !this.hasTouch
                            ? {}
                            : {
                                  draw_polygon: FreehandMode,
                              },
                    ),
                    styles: drawStyles,
                });
            }
            if (this.doFavoriteClusters) {
                map.addSource('favorites', {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: this.favorites,
                    },
                    cluster: true,
                    clusterMaxZoom: this.favoriteClustersMaxZoom,
                    clusterRadius: 37,
                });
                map.addLayer(this.layers.unClusteredFavorites);
                map.addLayer(this.layers.clustersCircleFavorites);
                map.addLayer(this.layers.clustersCountFavorites);

                mapObject.on('click', 'clustersCircleFavorites', (e: any) => {
                    const features = mapObject.queryRenderedFeatures(e.point, {
                        layers: ['clustersCircleFavorites'],
                    });
                    map.easeTo({
                        center: features[0].geometry.coordinates,
                        zoom: Math.round(
                            (this.currentZoom || map.getZoom()) + 1,
                        ),
                    });
                });
                map.on('mouseenter', 'clustersCircleFavorites', (e) => {
                    map.getCanvas().style.cursor = 'pointer';
                });
                map.on('mouseleave', 'clustersCircleFavorites', () => {
                    map.getCanvas().style.cursor = 'grab';
                });
                map.on('idle', () => {
                    if (this.opened) return; // prevent rerendering favorite markers on marker popup trigger
                    if (map.getSource('favorites')) {
                        const unClusteredFavorites = map.queryRenderedFeatures({
                            layers: ['unClusteredFavorites'],
                        });
                        if (unClusteredFavorites) {
                            this.filteredFavorites = unClusteredFavorites.map(
                                (feature: any) => {
                                    return this.formatFavoriteFromClusterFeature(
                                        feature,
                                    );
                                },
                            );
                        } else {
                            this.filteredFavorites = [];
                        }
                    }
                });
            }
        },

        /**
         * Handle "enter" event on previews transition.
         *
         * @return {void}
         */
        onEnterPreviews(): void {
            const propertyListElement = this.$refs.list as HTMLDivElement;
            const brokerListElement = this.$refs.brokerList as HTMLDivElement;
            let intersectionObserverElement = null;

            if (typeof propertyListElement === 'object') {
                intersectionObserverElement = this.$refs
                    .listPropertyCards as any[];
            }

            if (typeof brokerListElement === 'object') {
                intersectionObserverElement = this.$refs
                    .listBrokerCards as any[];
            }

            if (
                'IntersectionObserver' in window &&
                intersectionObserverElement
            ) {
                const observer = new IntersectionObserver(
                    (entries: IntersectionObserverEntry[]) => {
                        entries.forEach((entry: IntersectionObserverEntry) => {
                            if (entry.intersectionRatio > 0.8) {
                                let dataId = `${(entry.target as any).getAttribute('data-id')}`;
                                this.opened = dataId;
                                this.previewIndex = parseInt(
                                    (entry.target as any).getAttribute(
                                        'data-index',
                                    ),
                                );
                            }
                        });
                    },
                    {
                        root: document.querySelector('.m-map__list'),
                        rootMargin: '0px',
                        threshold: [0.8],
                    },
                );

                intersectionObserverElement.forEach((card: any) => {
                    observer.observe(card.$el);
                });
            }
        },

        /**
         * Handle "leave" event on previews transition.
         *
         * @return {void}
         */
        onLeavePreviews(): void {
            //
        },

        /**
         * Handle "update:zoom" event for Mapbox TS map.
         *
         * @param {number} newZoom
         * @return {void}
         */
        onZoomUpdate() {
            this.currentZoom = map.getZoom();
        },

        /**
         * Handle "moveend" event for Mapbox map.
         *
         * @param {any} event
         * @param {boolean} isProgrammatic
         * @return {void}
         */
        onMapMoveEnd(mapObject: any, isProgrammatic: boolean): void {
            this.$emit('move', {
                bounds: map.getBounds(),
                isUserInteraction:
                    isProgrammatic !== true &&
                    map?.mapboxEvent?.isProgrammatic !== true,
                latitude: map.getCenter().lat,
                longitude: map.getCenter().lng,
                zoom: map.getZoom(),
            });

            if (this.initialLoad) {
                this.initialLoad = false;

                if (this.fitProperties && this.properties.length) {
                    this.fitToProperties = false;
                    this.fitBounds('properties');
                } else if (this.fitProperties) {
                    this.fitToProperties = true;
                }

                if (this.fitNeighborhoods && this.neighborhoods.length) {
                    this.fitToNeighborhoods = false;
                    this.fitBounds('neighborhoods');
                } else if (this.fitNeighborhoods) {
                    this.fitToNeighborhoods = true;
                }

                return;
            }

            if (this.fitToBrokers) {
                this.fitToBrokers = false;
                this.fitBounds('brokers');
            }

            this.currentZoom = map.getZoom();

            if (
                this.fitted === true &&
                this.mapType !== 'propertymap' &&
                this.mapType !== 'neighborhoodmap'
            ) {
                map.setMaxBounds(map.getBounds());

                this.fitted = false;
            }
        },

        /**
         * Handle "move" event for Mapbox map.
         *
         * @param {any} event
         * @param {boolean} isProgrammatic
         * @return {void}
         */
        onMapMove(): void {
            this.opened = '';
            this.currentFavorite = null;
        },

        /**
         * Handle "click" event for Mapbox marker.
         *
         * @param {any} event
         * @return {void}
         */
        onMarkerClick(event: any, poi: POI | null): void {
            this.opened = `${event.target.closest('.maplibregl-marker').getAttribute('data-id')}`;

            this.previewIndex = 0;
            Vue.nextTick(() => {
                if (this.opened) {
                    const scrollElement = document.querySelector(
                        `.m-map__listItem[data-id="${this.opened}"]`,
                    );
                    scrollElement?.scrollIntoView({
                        behavior: 'instant',
                        block: 'end',
                        inline: 'center',
                    });

                    // If we are on the neighborhood page, make sure to scroll to the bottom of the map as well
                    const neighborhoodMapElement =
                        document.querySelector('.o-neighborhoodMap');

                    neighborhoodMapElement?.scrollIntoView({
                        behavior: 'instant',
                        block: 'end',
                        inline: 'center',
                    });
                }
                if (poi) {
                    this.$emit('poiClicked', {
                        poi,
                        zoomLevel: this.currentZoom,
                    });
                }
            });
        },

        /**
         * Handle "close" event for Mapbox POI popup.
         *
         * @return {void}
         */
        onPoiPopupClose(event: any): void {
            this.previewIndex = 0;

            if (
                this.currentPoi?.geometry[1] ===
                event.target._marker._element
                    .querySelector('.mapboxgl-marker')
                    .getAttribute('data-id')
            ) {
                this.setCurrentPoi(undefined);
                this.$emit('close');
            }
        },

        /**
         * Handle "close" event for Mapbox popup.
         *
         * @return {void}
         */
        onPopupClose(popupObject): void {
            this.previewIndex = 0;
            propertyPreviewProps.updateCurrentSlide = true;
            if (
                this.opened ===
                popupObject.target._content
                    .querySelector('.popup-contentWrapper')
                    .getAttribute('data-id')
            ) {
                this.opened = '';
                this.$emit('close');
            }
        },

        /**
         * Handle "open" event for Mapbox popup.
         *
         * @param {any} event
         * @return {void}
         */
        onPopupOpen(popupObject): void {
            this.opened = `${popupObject.target._content.querySelector('.popup-contentWrapper')?.getAttribute('data-id')}`;
            this.openPopupObject = popupObject;
            propertyPreviewProps.updateCurrentSlide = false;
            this.$emit(
                'open',
                `${popupObject.target._content.querySelector('.popup-contentWrapper').getAttribute('data-id')}`,
            );
        },

        /**
         * Handle favorite click event on mobile.
         *
         * @return {void}
         */
        onFavoriteClick(e: Event, favorite: MapFavorite): void {
            this.previewIndex = 0;
            this.opened = '';

            this.currentFavorite = favorite;
        },

        /**
         * Handle POI click event on mobile.
         *
         * @return {void}
         */
        onPoiClick(poi: POI): void {
            this.setCurrentPoi(poi);
            this.currentFavorite = null;
        },

        onPoiClose(): void {
            this.previewIndex = 0;
            this.setCurrentPoi(undefined);
            this.opened = '';
            this.$emit('close');
        },

        /**
         * Reset map to initial state.
         *
         * @return {void}
         */
        onReset(): void {
            this.fitToProperties = true;
            this.currentFavorite = null;
            this.setCurrentPoi(undefined);
        },

        /**
         * Set currently hovered marker.
         *
         * @param {string | null} value
         * @return {void}
         */
        setHovered(value: string | null): void {
            if (this.isDesktop) {
                this.$emit('hover', value);
            }
        },

        /**
         * Set the current preview index.
         *
         * @param {number} index
         * @return {void}
         */
        setPreviewIndex(index: number): void {
            this.previewIndex = index;
            propertyPreviewProps.updateCurrentSlide = true;
            propertyPreviewProps.index = index;
        },

        /**
         * Zoom one step in.
         *
         * @return {void}
         */
        zoomIn(): void {
            map.setZoom(Math.round((this.currentZoom || map.getZoom()) + 1));
        },

        /**
         * Zoom in one step.
         *
         * @return {void}
         */
        easeZoomIn(): void {
            if (!map) return;
            map.easeTo({
                center: this.center,
                zoom: Math.round((this.currentZoom || map.getZoom()) + 1),
            });
        },

        /**
         * Zoom one step in.
         *
         * @return {void}
         */
        zoomOut(): void {
            map.setZoom(Math.round((this.currentZoom || map.getZoom()) - 1));
        },
    },
};

const propertyObject: Ref<MapProperty | undefined> = ref();

const propertyPreviewProps = Vue.reactive({
    index: 0,
    updateCurrentSlide: false,
    get property() {
        return propertyObject.value.properties.data[this.index];
    },
    get total() {
        return propertyObject.value.properties.data.length;
    },
    isPin: true,
});

const brokerObject: Ref<MapBroker | undefined> = ref();

const brokerPreviewProps = Vue.reactive({
    index: 0,
    get broker() {
        return brokerObject.value.properties.data;
    },
    isPin: true,
});
