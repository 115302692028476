import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  ref: "buyerAdviceForm",
  class: "o-buyerAdviceForm"
}
const _hoisted_2 = {
  key: 0,
  class: "o-buyerAdviceForm__stepWrapper"
}
const _hoisted_3 = ["textContent"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "o-buyerAdviceForm__form" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "m-button__text" }
const _hoisted_8 = {
  key: 1,
  class: "o-buyerAdviceForm__stepWrapper o-buyerAdviceForm__stepWrapper--confirmation"
}
const _hoisted_9 = ["textContent"]
const _hoisted_10 = ["textContent"]
const _hoisted_11 = { class: "o-buyerAdviceForm__confirmationCheckmark" }
const _hoisted_12 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_m_input = _resolveComponent("m-input")!
  const _component_m_textarea = _resolveComponent("m-textarea")!
  const _component_a_icon = _resolveComponent("a-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($data.step === 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("h2", {
            class: "a-heading1 o-buyerAdviceForm__heading",
            textContent: _toDisplayString($props.texts.headline)
          }, null, 8 /* PROPS */, _hoisted_3),
          _createElementVNode("div", {
            ref: "introText",
            class: "a-paragraph o-buyerAdviceForm__introText",
            innerHTML: $props.texts.bodyText
          }, null, 8 /* PROPS */, _hoisted_4),
          _createElementVNode("div", _hoisted_5, [
            ($props.brokerId === '')
              ? (_openBlock(), _createBlock(_component_m_input, {
                  key: 0,
                  value: $data.form.address,
                  class: "o-salesValuation__input--dawa full-width",
                  name: "address‌_search",
                  "use-bright-error": true,
                  error: $data.errors.address,
                  label: `${$props.formData.addressPlaceholder}*`,
                  type: "dawa",
                  onDawa: $options.enableSubmit,
                  onDawaReset: $options.disableSubmit,
                  onInput: $options.setAddressInfo,
                  onFocus: _cache[0] || (_cache[0] = ($event: any) => ($options.setIsFocused(true))),
                  onBlur: _cache[1] || (_cache[1] = ($event: any) => ($options.setIsFocused(false)))
                }, null, 8 /* PROPS */, ["value", "error", "label", "onDawa", "onDawaReset", "onInput"]))
              : _createCommentVNode("v-if", true),
            _createVNode(_component_m_input, {
              modelValue: $data.form.name,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($data.form.name) = $event)),
              name: "name",
              "use-bright-error": true,
              error: $data.errors.name,
              label: `${$props.formData.namePlaceholder}*`
            }, null, 8 /* PROPS */, ["modelValue", "error", "label"]),
            _createVNode(_component_m_input, {
              modelValue: $data.form.mail,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($data.form.mail) = $event)),
              name: "mail",
              "use-bright-error": true,
              type: "email",
              error: $data.errors.mail,
              label: `${$props.formData.mailPlaceholder}*`
            }, null, 8 /* PROPS */, ["modelValue", "error", "label"]),
            _createVNode(_component_m_input, {
              modelValue: $data.form.phone,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($data.form.phone) = $event)),
              name: "phone",
              "use-bright-error": true,
              error: $data.errors.phone,
              label: `${$props.formData.phonePlaceholder}*`
            }, null, 8 /* PROPS */, ["modelValue", "error", "label"]),
            _createVNode(_component_m_textarea, {
              modelValue: $data.form.message,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($data.form.message) = $event)),
              name: "message",
              class: "full-width",
              error: $data.errors.message,
              label: `${$props.formData.messagePlaceholder}`
            }, null, 8 /* PROPS */, ["modelValue", "error", "label"])
          ]),
          _createElementVNode("div", {
            class: "a-small a-small--allowBreak o-buyerAdviceForm__disclaimer",
            innerHTML: $props.consent.purposeText
          }, null, 8 /* PROPS */, _hoisted_6),
          _createElementVNode("button", {
            type: "submit",
            class: _normalizeClass(["m-button o-buyerAdviceForm__inputButton", { isLoading: $data.isLoading }]),
            onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => ($options.onSubmit && $options.onSubmit(...args)))
          }, [
            _createElementVNode("span", _hoisted_7, _toDisplayString($props.texts.buttonText), 1 /* TEXT */)
          ], 2 /* CLASS */)
        ]))
      : _createCommentVNode("v-if", true),
    ($data.step === 2)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          ($props.texts.trumpetReceipt)
            ? (_openBlock(), _createElementBlock("p", {
                key: 0,
                class: "a-label",
                textContent: _toDisplayString($props.texts.trumpetReceipt)
              }, null, 8 /* PROPS */, _hoisted_9))
            : _createCommentVNode("v-if", true),
          ($options.formattedHeadlineReceiptText)
            ? (_openBlock(), _createElementBlock("h2", {
                key: 1,
                class: "a-heading2",
                textContent: _toDisplayString($options.formattedHeadlineReceiptText)
              }, null, 8 /* PROPS */, _hoisted_10))
            : _createCommentVNode("v-if", true),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_a_icon, { name: "check" })
          ]),
          ($props.texts.bodyTextReceipt)
            ? (_openBlock(), _createElementBlock("p", {
                key: 2,
                class: "a-label",
                textContent: _toDisplayString($props.texts.bodyTextReceipt)
              }, null, 8 /* PROPS */, _hoisted_12))
            : _createCommentVNode("v-if", true)
        ]))
      : _createCommentVNode("v-if", true)
  ], 512 /* NEED_PATCH */))
}