import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  ref: "newsLetterForm",
  class: "a-section"
}
const _hoisted_2 = { class: "u-container" }
const _hoisted_3 = {
  key: 0,
  class: "o-newsletter__stepWrapper"
}
const _hoisted_4 = ["textContent"]
const _hoisted_5 = { class: "o-newsletter__information" }
const _hoisted_6 = ["textContent"]
const _hoisted_7 = { class: "o-newsletter__inputWrapper" }
const _hoisted_8 = {
  key: 0,
  class: "o-newsletter__input--consent"
}
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = ["textContent"]
const _hoisted_11 = {
  key: 1,
  class: "o-newsletter__stepWrapper o-newsletter__stepWrapper--confirmation"
}
const _hoisted_12 = { class: "o-newsletter__confirmation" }
const _hoisted_13 = ["textContent"]
const _hoisted_14 = { class: "o-newsletter__confirmationNotice" }
const _hoisted_15 = { class: "o-newsletter__confirmationCheckmark" }
const _hoisted_16 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_m_input = _resolveComponent("m-input")!
  const _component_m_select = _resolveComponent("m-select")!
  const _component_m_checkbox = _resolveComponent("m-checkbox")!
  const _component_a_icon = _resolveComponent("a-icon")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["o-newsletter", `o-newsletter--${$props.texts.backgroundColour}`])
      }, [
        ($data.step === 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("h2", {
                class: "a-heading2 o-newsletter__headline",
                textContent: _toDisplayString($props.texts.headline)
              }, null, 8 /* PROPS */, _hoisted_4),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("p", {
                  class: "a-paragraph",
                  textContent: _toDisplayString($props.texts.helpText)
                }, null, 8 /* PROPS */, _hoisted_6)
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_m_input, {
                  modelValue: $data.form.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.form.name) = $event)),
                  class: "m-input__field o-newsletter__input",
                  name: "name",
                  label: `${$props.placeholders.namePlaceholder}*`,
                  error: $data.errors.name
                }, null, 8 /* PROPS */, ["modelValue", "label", "error"]),
                _createVNode(_component_m_input, {
                  modelValue: $data.form.mail,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.form.mail) = $event)),
                  class: "m-input__field o-newsletter__input",
                  name: "mail",
                  label: `${$props.placeholders.mailPlaceholder}*`,
                  error: $data.errors.mail,
                  type: "email"
                }, null, 8 /* PROPS */, ["modelValue", "label", "error"]),
                _createVNode(_component_m_select, {
                  modelValue: $data.form.municipality,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($data.form.municipality) = $event)),
                  class: "o-newsletter__input o-newsletter__input--select",
                  name: "municipality",
                  error: $data.errors.municipality,
                  options: $options.municipalityOptions
                }, null, 8 /* PROPS */, ["modelValue", "error", "options"]),
                ($props.texts.consentText || $options.checkboxLabel)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      ($props.texts.consentText)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: "o-newsletter__disclaimer",
                            innerHTML: $props.texts.consentText
                          }, null, 8 /* PROPS */, _hoisted_9))
                        : _createCommentVNode("v-if", true),
                      ($options.checkboxLabel)
                        ? (_openBlock(), _createBlock(_component_m_checkbox, {
                            key: 1,
                            modelValue: $data.form.consent,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($data.form.consent) = $event)),
                            class: "m-input__field",
                            error: $data.errors.consent,
                            label: $options.checkboxLabel
                          }, null, 8 /* PROPS */, ["modelValue", "error", "label"]))
                        : _createCommentVNode("v-if", true)
                    ]))
                  : _createCommentVNode("v-if", true),
                _createElementVNode("button", {
                  type: "button",
                  class: _normalizeClass(["m-button o-newsletter__submit", { isLoading: $data.isLoading }]),
                  onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => ($options.onSubmit && $options.onSubmit(...args)))
                }, [
                  _createElementVNode("span", {
                    class: "m-button__text",
                    textContent: _toDisplayString($props.texts.buttonText)
                  }, null, 8 /* PROPS */, _hoisted_10)
                ], 2 /* CLASS */)
              ])
            ]))
          : _createCommentVNode("v-if", true),
        ($data.step === 2)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("h2", {
                  class: "a-heading2",
                  textContent: _toDisplayString($props.texts.confirmationHeadline)
                }, null, 8 /* PROPS */, _hoisted_13),
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("div", _hoisted_15, [
                    _createVNode(_component_a_icon, { name: "check" })
                  ]),
                  _createElementVNode("span", {
                    class: "a-label",
                    textContent: _toDisplayString($props.texts.confirmationText)
                  }, null, 8 /* PROPS */, _hoisted_16)
                ])
              ])
            ]))
          : _createCommentVNode("v-if", true)
      ], 2 /* CLASS */)
    ])
  ], 512 /* NEED_PATCH */))
}