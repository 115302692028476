import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "o-neighborhoodBrokerOverlay" }
const _hoisted_2 = { class: "o-neighborhoodBrokerButton" }
const _hoisted_3 = ["textContent"]
const _hoisted_4 = {
  key: "broker",
  class: "o-panel"
}
const _hoisted_5 = ["textContent"]
const _hoisted_6 = {
  key: "brokerContact",
  class: "o-subPanel"
}
const _hoisted_7 = ["textContent"]
const _hoisted_8 = { class: "o-fields" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "o-buttons o-subPanel__actions o-subPanel__actions--singleCTA" }
const _hoisted_11 = ["textContent"]
const _hoisted_12 = {
  key: "broker2",
  class: "o-subPanel"
}
const _hoisted_13 = ["textContent"]
const _hoisted_14 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_m_select = _resolveComponent("m-select")!
  const _component_o_employee_card = _resolveComponent("o-employee-card")!
  const _component_m_textarea = _resolveComponent("m-textarea")!
  const _component_m_input = _resolveComponent("m-input")!
  const _component_portal = _resolveComponent("portal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: "m-button",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.open && $options.open(...args)))
      }, [
        _createElementVNode("span", {
          class: "m-button__text",
          textContent: _toDisplayString($props.brokerContact.broker.buttonText)
        }, null, 8 /* PROPS */, _hoisted_3)
      ])
    ]),
    ($options.showModal)
      ? (_openBlock(), _createBlock(_component_portal, {
          key: 0,
          to: "modal"
        }, {
          default: _withCtx(() => [
            _createVNode(_Transition, {
              name: `o-panel--${$data.currentDirection}`
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("h2", {
                    class: "a-heading2 o-panel__heading",
                    textContent: _toDisplayString($props.brokerContact.popupHeading)
                  }, null, 8 /* PROPS */, _hoisted_5),
                  _createVNode(_Transition, {
                    name: "fade",
                    mode: "out-in"
                  }, {
                    default: _withCtx(() => [
                      ($data.currentSubPanel === 1)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                            ($props.employees.length > 1)
                              ? (_openBlock(), _createBlock(_component_m_select, {
                                  key: 0,
                                  modelValue: $data.form.selectedEmployee,
                                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.form.selectedEmployee) = $event)),
                                  class: "o-panel__select",
                                  error: $data.errors.responsibleShopNo,
                                  options: $options.brokerStores
                                }, null, 8 /* PROPS */, ["modelValue", "error", "options"]))
                              : _createCommentVNode("v-if", true),
                            ($data.form.selectedEmployee)
                              ? (_openBlock(), _createBlock(_component_o_employee_card, {
                                  key: 1,
                                  panel: true,
                                  employee: $props.employees[$data.form.selectedEmployee],
                                  class: "o-panel__brokerCard"
                                }, null, 8 /* PROPS */, ["employee"]))
                              : _createCommentVNode("v-if", true),
                            _createElementVNode("p", {
                              class: "a-paragraph o-subPanel__description",
                              textContent: _toDisplayString($props.brokerContact.popupDescription)
                            }, null, 8 /* PROPS */, _hoisted_7),
                            _createElementVNode("div", _hoisted_8, [
                              _createVNode(_component_m_textarea, {
                                modelValue: $data.form.message,
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($data.form.message) = $event)),
                                class: "o-fields__field",
                                error: $data.errors.message,
                                label: `${$props.texts.message}*`
                              }, null, 8 /* PROPS */, ["modelValue", "error", "label"]),
                              _createVNode(_component_m_input, {
                                modelValue: $data.form.name,
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($data.form.name) = $event)),
                                class: "o-fields__field",
                                error: $data.errors.name,
                                label: `${$props.texts.name}*`
                              }, null, 8 /* PROPS */, ["modelValue", "error", "label"]),
                              _createVNode(_component_m_input, {
                                modelValue: $data.form.mail,
                                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($data.form.mail) = $event)),
                                class: "o-fields__field",
                                error: $data.errors.mail,
                                label: `${$props.texts.mail}*`
                              }, null, 8 /* PROPS */, ["modelValue", "error", "label"]),
                              _createVNode(_component_m_input, {
                                modelValue: $data.form.phone,
                                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($data.form.phone) = $event)),
                                class: "o-fields__field",
                                error: $data.errors.phone,
                                label: `${$props.texts.phone}*`
                              }, null, 8 /* PROPS */, ["modelValue", "error", "label"]),
                              ($props.consent)
                                ? (_openBlock(), _createElementBlock("div", {
                                    key: 0,
                                    class: "a-small a-small--allowBreak",
                                    innerHTML: $props.consent.purposeText
                                  }, null, 8 /* PROPS */, _hoisted_9))
                                : _createCommentVNode("v-if", true)
                            ]),
                            _createElementVNode("div", _hoisted_10, [
                              _createElementVNode("button", {
                                type: "submit",
                                class: _normalizeClass(["m-button", { isLoading: $data.isLoading }]),
                                onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => ($options.onSubmit && $options.onSubmit(...args)))
                              }, [
                                _createElementVNode("span", {
                                  class: "m-button__text",
                                  textContent: _toDisplayString($props.texts.submitButtonText)
                                }, null, 8 /* PROPS */, _hoisted_11)
                              ], 2 /* CLASS */)
                            ])
                          ]))
                        : ($data.currentSubPanel === 2)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                              ($data.form.selectedEmployee)
                                ? (_openBlock(), _createBlock(_component_o_employee_card, {
                                    key: 0,
                                    panel: true,
                                    employee: $props.employees[$data.form.selectedEmployee],
                                    class: "o-panel__brokerCard"
                                  }, null, 8 /* PROPS */, ["employee"]))
                                : _createCommentVNode("v-if", true),
                              _createElementVNode("p", {
                                class: "a-paragraph o-subPanel__description",
                                textContent: _toDisplayString($props.texts.headingReceipt)
                              }, null, 8 /* PROPS */, _hoisted_13),
                              _createElementVNode("p", {
                                class: "a-paragraph",
                                textContent: _toDisplayString($data.successMessage)
                              }, null, 8 /* PROPS */, _hoisted_14)
                            ]))
                          : _createCommentVNode("v-if", true)
                    ], undefined, true),
                    _: 1 /* STABLE */
                  })
                ])
              ], undefined, true),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["name"])
          ], undefined, true),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true)
  ]))
}