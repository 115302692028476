import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["textContent"]
const _hoisted_3 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_icon = _resolveComponent("a-icon")!

  return (_ctx.isLoggedIn)
    ? (_openBlock(), _createElementBlock("button", {
        key: 0,
        type: "button",
        class: _normalizeClass(["o-stickyFavoriteButton m-button m-button--shadowed", {
            isFavorite:
                $options.propertyIsFavorite && this.texts.loadingLabel !== $data.buttonText,
        }]),
        disabled: $data.isSaving,
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.onLoggedInClick($event)))
      }, [
        _createVNode(_component_a_icon, {
          name: "favorite_animated",
          class: "m-button__icon a-icon--favorite"
        }),
        _createElementVNode("span", {
          class: _normalizeClass(["m-button__text", {
                animateIn: $data.addAnimationIn && !$data.isDesktop,
                animateOut: $data.addAnimationOut && !$data.isDesktop,
            }]),
          ref: "buttonText",
          textContent: _toDisplayString($data.buttonText)
        }, null, 10 /* CLASS, PROPS */, _hoisted_2)
      ], 10 /* CLASS, PROPS */, _hoisted_1))
    : (_openBlock(), _createElementBlock("button", {
        key: 1,
        type: "button",
        class: "o-stickyFavoriteButton m-button m-button--shadowed",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.onNotLoggedInClick && $options.onNotLoggedInClick(...args)))
      }, [
        _createVNode(_component_a_icon, {
          name: "favorite_animated",
          class: "a-icon--favorite"
        }),
        _createElementVNode("span", {
          class: "m-button__text",
          textContent: _toDisplayString($data.buttonText)
        }, null, 8 /* PROPS */, _hoisted_3)
      ]))
}