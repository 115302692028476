
import { PropType } from 'vue';
import Dawa from '@/interfaces/dawa.interface';
import LeadConsent from '@/interfaces/leadConsent.interface';
import Api from '@/functions/api';
import { LeadTypes } from '@/enums/leadtypes.enum';
import tracking from '@/functions/tracking';
import Format from '@/functions/format';
import FullLead from '@/interfaces/leads/lead.interface';

export interface Texts {
    headline: string;
    bodyText: string;
    trumpetReceipt: string;
    headlineReceipt: string;
    bodyTextReceipt: string;
    buttonText: string;
}

export interface Data {
    form: {
        address: string;
        name: string;
        mail: string;
        phone: string;
        message?: string;
        zipcode?: string;
        awsAddressGuid?: string;
    };
    errors: {
        address?: string;
        name?: string;
        mail?: string;
        phone?: string;
        message?: string;
    };
    addressFound: boolean;
    isFocused: boolean;
    isLoading: boolean;
    step: number;
    isFormInitiated: false;
}

export interface FormData {
    addressPlaceholder: string;
    namePlaceholder: string;
    mailPlaceholder: string;
    phonePlaceholder: string;
    messagePlaceholder: string;
}

export default {
    props: {
        texts: {
            type: Object as PropType<Texts>,
            default: () => ({
                headline: '',
                bodyText: '',
                trumpetReceipt: '',
                headlineReceipt: '',
                bodyTextReceipt: '',
                buttonText: '',
            }),
        },
        brokerId: {
            type: String as PropType<string>,
            default: () => '',
        },
        consent: {
            type: Object as PropType<LeadConsent>,
            default: () => ({
                id: '',
                purposeText: '',
            }),
        },
        formData: {
            type: Object as PropType<FormData>,
            default: () => ({
                addressPlaceholder: '',
                namePlaceholder: '',
                mailPlaceholder: '',
                phonePlaceholder: '',
                messagePlaceholder: '',
            }),
        },
    },

    data(): Data {
        return {
            form: {
                address: '',
                name: '',
                mail: '',
                phone: '',
                message: '',
                zipcode: '',
                awsAddressGuid: '',
            },
            errors: {},
            isFocused: false,
            isLoading: false,
            addressFound: false,
            step: 1,
            isFormInitiated: false,
        };
    },

    computed: {
        formattedHeadlineReceiptText(): string {
            let formattedText = this.texts.headlineReceipt;
            formattedText = formattedText.replace('#navn#', this.form.name);
            formattedText = formattedText.replace('#mail#', this.form.mail);
            formattedText = formattedText.replace('#telefon#', this.form.phone);

            return formattedText;
        },
    },

    watch: {
        form: {
            deep: true,
            handler() {
                this.errors = {};
                if (!this.isFormInitiated) {
                    tracking.trackRawEvent({
                        event: 'trackForm',
                        eventData: {
                            category: 'Formular',
                            action: 'Koeberraadgivning indgang',
                            label: 'Koeberraadgivning',
                            formularStepnavn: 'Brugerinformation',
                            formularIndgang: 'Aaben formular',
                            maeglerId: this.brokerId,
                        },
                    });
                    this.isFormInitiated = true;
                }
            },
        },
    },

    mounted() {
        const element = this.$refs.introText as HTMLDivElement;
        const anchors = element.querySelectorAll('a');

        if (anchors.length > 0) {
            const firstAnchor = anchors[0] as HTMLAnchorElement;
            firstAnchor.addEventListener('click', () => {
                tracking.trackRawEvent({
                    event: 'trackCta',
                    eventData: {
                        category: 'Koeberraadgivning',
                        action: 'Koeberraadgivning link klikket',
                        label: firstAnchor.textContent,
                    },
                });
            });
        }
    },

    methods: {
        /**
         * Enable submit button.
         *
         * @return {void}
         */
        enableSubmit(): void {
            this.addressFound = true;
        },
        /**
         * Disable submit button.
         *
         * @return {void}
         */
        disableSubmit(): void {
            this.addressFound = false;
        },
        /**
         * Set address information if address is found
         *
         * @return {void}
         */
        setAddressInfo(value: Dawa): void {
            // this.errors.address = '';
            if (this.addressFound) {
                this.form.address = value.tekst;
                this.form.zipcode = value.data.postnr;
                this.form.awsAddressGuid = value.data.id;
            }
        },
        /**
         * Set whether input field is focused
         *
         * @return {void}
         */
        setIsFocused(value: boolean): void {
            this.isFocused = value;
        },

        /**
         * Submit a form.
         *
         * @param {string} value
         * @return {void}
         */
        onSubmit(): void {
            this.isLoading = true;

            let excludeValidation: string[] = [];

            const lead: FullLead = {
                consentIdGlobal: this.consent?.id ?? '',
                address: this.form.address.trim() ?? '',
                addressZipCode: this.form.zipcode?.trim() ?? '',
                email: this.form.mail.trim() ?? '',
                firstName: Format.firstName(this.form.name) ?? '',
                lastName: Format.lastName(this.form.name) ?? '',
                message: this.form.message ?? '',
                phoneNumber: this.form.phone ?? '',
                responsibleShopNo: this.brokerId ?? '',
                type: LeadTypes.BuyersAdvice.toString(),
            };

            // Only add this property if the value is NOT empty, otherwise the BE will choke on the lead object
            if (this.form.awsAddressGuid?.trim() !== '') {
                lead.awsAddressGuid = this.form.awsAddressGuid?.trim();
            }

            if (this.brokerId !== '') {
                // excludeValidation = this.form.address.trim() === '' ? ['address'] : [];

                if (this.form.address.trim() === '') {
                    excludeValidation.push('address');
                    excludeValidation.push('addressZipCode');
                }

                delete lead.awsAddressGuid;
            }

            Api.postLead(lead, excludeValidation)
                .then(() => {
                    this.step = 2;
                    tracking.trackRawEvent({
                        event: 'trackForm',
                        eventData: {
                            category: 'Formular',
                            action: 'Koeberraadgivning gennemfoert',
                            label: 'Koeberraadgivning',
                            formularStepnavn: 'Kvittering',
                            formularIndgang: 'Aaben formular',
                            maeglerId: this.brokerId,
                        },
                    });
                })
                .catch((error: any) => {
                    this.errors = error.errors ?? [];
                })
                .finally(() => {
                    this.isLoading = false;

                    const buyerAdviceForm = this.$refs
                        .buyerAdviceForm as HTMLDivElement;
                    buyerAdviceForm?.scrollIntoView();
                });
        },
    },
};
